import React from 'react';
import { Modal, Button, Box, Typography } from '@mui/material';

const ModalConexaoDeletada = ({ modalOpen, setModalOpen }) => {
  return (
    <Modal
      fullWidth
      open={modalOpen}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          outline: 'none',
          p: 2,
          width: [240, 358, 442, 585],
          height: [190, 140],
          backgroundColor: '#1C1F1E',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Typography variant="h5" color="primary" sx={{ mb: 4, color: '#ff66c4' }}>
          Conexão deletada
        </Typography>
        <Typography variant="p" sx={{ color: '#bababa', mb: 4 }}>
          Caso adicione a conexão novamente, todos os treinos serão recuperados.
        </Typography>
        <Button
          variant="outlined"
          color="success"
          onClick={setModalOpen}
          sx={{
            alignSelf: 'end',
            border: 'none',
            fontWeight: 'bold',
            width: 200,
          }}
        >
          FINALIZAR
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalConexaoDeletada;
