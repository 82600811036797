import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Card,
  Button,
  Fab,
  Box,
  Container,
  Typography,
  // CardContent,
  // CardActions,
  Tooltip,
  // CircularProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import IconeTreino from '../../assets/IconeTreino2.svg';
import IconePerfil from '../../assets/IconePerfil2.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TemporaryDrawer from '../../components/headerV2/Drawer';
import AlertDialog from './components/Alert';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ModalDeletar from './components/modal-deletar';
import ModalConexaoDeletada from './components/modal-conexao-deletada';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AlunoApi from '../../services/aluno';
import VinculoApi from '../../services/vinculo';
import UsersApi from '../../services/users'
// import { DateTime } from 'luxon';
import { useStore } from '../../hooks';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ModalUpgradePlano from './components/modal-upgrade-plano'
// import LockResetIcon from '@mui/icons-material/LockReset';
import ModalResetarSenha from './components/modal-resetar-senha';

const Notifications = () => {
  const [rows, setRows] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [filterText2, setFilterText2] = useState('');
  
  const [whatAlert, setWhatAlert] = useState('');
  // const [tooltipAdicionar, setTooltipAdicionar] = useState(false);
  const [tooltipRelacao, setTooltipRelacao] = useState(false);
  const [modalDeletar, setModalDeletar] = useState(false);
  const [modalConexaoDeletada, setModalConexaoDeletada] = useState(false);
  const [modalPlano, setModalPlano] = useState(false);
  const [conexaoDeletada, setConexaoDeletada] = useState('');
  const [loading, setLoading] = useState(false);
  const [finishStatus, setfinishStatus] = useState(false);
  const [modalResetar, setModalResetar] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  // const [conexaoResetar, setConexaoResetar] = useState('');

  const analytics = getAnalytics();

  useEffect(() => {
    // eslint-disable-next-line camelcase
    logEvent(analytics, 'page_view', { page_location: '/relacao-alunos', page_title: 'relação de alunos' })
  }, [])

  const navigate = useNavigate();
  const store = useStore();

  const { user } = store.getUser();

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm('Deseja sair da página?')) {
        setfinishStatus(true);
        store.removeUser();
        navigate('/');
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  // const handleChangeTooltipAdicionar = () =>
  //   setTooltipAdicionar(!tooltipAdicionar);

  const handleChangeTooltipRelacao = () => setTooltipRelacao(!tooltipRelacao);

  // eslint-disable-next-line no-unused-vars
  const [expenseDate, setExpenseDate] = useState();

  // const [dataValue, setDataValue] = useState({
  //   dataInicial: expenseDate,
  //   dataFinal: expenseDate,
  // });

  const validationSchema = yup.object({
    emailTest: yup
      .string('insira um email')
      .email('Insira um e-mail válido')
      .required('Email é obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      emailTest: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const getAlunos = async () => {
    setLoading(true);

    await UsersApi.getAllNotifications()
      .then((data) => {
        setRows([])
        setTimeout(() => {
          if(!data.error){
            setRows(data);
          }
          
        }, 500)
        
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const handleSetModalPlano = () => {
    setModalPlano(!modalPlano);
  };

  // const vincular = async (email, idPersonal) => {
  //   setLoading(true);
  //   setWhatAlert('notfound');

  //   await VinculoApi.link(idPersonal, email)
  //     .then(() => {
  //       toast.success('Aluno vinculado com sucesso');
  //       setWhatAlert('succsess');
  //       getAlunos();
  //       setShowAlert(true);
  //       formik.resetForm({ emailTest: '' });
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       if(error.response.data.error === 'Você atingiu o limite máximo de alunos para o seu plano!'){
  //         setShowAlert(false)
  //         handleSetModalPlano();
  //       }
  //     });
  //   setLoading(false);
  // };

  // const addStudent = async (email) => {
  //   setLoading(true);
  //   setWhatAlert('notfound');

  //   await AlunoApi.getAlunoByEmail(email)
  //     .then(async () => {
  //       await vincular(email, user);
  //       getAlunos();
  //     })
  //     .catch(() => {
  //       setShowAlert(true);
  //     });
  //   setLoading(false);
  // };

  const handleRegisterNew = async (nome) => {
    setLoading(true);

    await AlunoApi.registerAluno(nome, formik.values.emailTest)
      .then(async () => {
        await VinculoApi.link(user, formik.values.emailTest).then(async () => {
          setWhatAlert('register-new');
          await getAlunos();
          formik.resetForm({ emailTest: '' });
        });
      })
      .catch((error) => {
        if(error.response.data.error === 'Você atingiu o limite máximo de alunos para o seu plano!'){
          setShowAlert(false)
          handleSetModalPlano();
        }
        console.log(error);
      });
    setLoading(false);
  };

  const handleSetModalDeletar = () => {
    setModalDeletar(!modalDeletar);
  };

  const handleSetModalResetar = () => {
    setModalResetar(!modalResetar)
  }

  const columnsMobile = [
    // { field: 'email', headerName: 'Email', flex: 1, minWidth: 150 },
    // {
    //   field: 'dataNascimento',
    //   headerName: 'Data de nascimento',
    //   flex: 1,
    //   minWidth: 100,
    // },
    // {
    //   field: 'dataConexao',
    //   headerName: 'Data conexão',
    //   flex: 1,
    //   minWidth: 100,
    //   type: 'dateTime',
    //   renderCell: (params) => {
    //     const date =
    //       params.row.personais[
    //         params.row.personais.findIndex((e) => e.id === user)
    //       ].dataConexao;

    //     const dateParsed = DateTime.fromMillis(parseInt(date)).toLocaleString();

    //     return <>{dateParsed}</>;
    //   },
    // },
    // {
    //   field: 'treinosAtivos',
    //   headerName: 'Treinos ativos',
    //   flex: 1,
    //   minWidth: 70,
    //   renderCell: (params) => {
    //     return (
    //       <>{params.row.treinosAtivos ? params.row.treinosAtivoslenght : 0}</>
    //     );
    //   },
    // },
    {
      field: 'atalhos',
      headerName: 'Atalhos',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <Box>
          <Fab
            size="small"
            aria-label="Usuario"
            sx={{ boxShadow: 0, backgroundColor: '#fff' }}
            onClick={() =>
              navigate('/periodizacao', { state: { id: params.row._id } })
            }
          >
            <img
              src={IconeTreino}
              width="60%"
              alt="Icone Treino"
              color="primary"
            />
          </Fab>
          <Fab
            sx={{ marginLeft: 2, boxShadow: 0, backgroundColor: '#fff' }}
            size="small"
            aria-label="Treino"
            color="inherit"
            onClick={() =>
              navigate('/perfil-conexao', { state: { id: params.row._id } })
            }
          >
            <img src={IconePerfil} alt="Icone Treino" color="primary" />
          </Fab>
        </Box>
      ),
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        <Box>
        <Fab
          size="small"
          aria-label="Usuario"
          sx={{
            shadowOffset: { width: 0, height: 0 },
            shadowRadius: 0,
            boxShadow: 0,
            backgroundColor: '#fff',
          }}
        >
          <DeleteIcon
            color="success"
            onClick={() => {
              setConexaoDeletada(params.row._id);
              handleSetModalDeletar();
            }}
          />
        </Fab>
        {/* <Fab
            size="small"
            aria-label="Reset senha"
            sx={{ ml: 1.3, boxShadow: 0, backgroundColor: '#fff' }}
            onClick={() =>{
              setConexaoResetar(params.row.email);
              handleSetModalResetar()
            }
            }
          >
            <LockResetIcon color="success" />
          </Fab> */}
          </Box>
      ),
    },
  ];

  console.log(columnsMobile)

  const columns = [
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 150 },
    { field: 'pushToken', headerName: 'Push Token', flex: 1, minWidth: 150 },
    { field: 'status', headerName: 'Status', flex: 1, minWidth: 150 },
    { field: 'error', headerName: 'Erro', flex: 1, minWidth: 150 },
    { field: 'detailError', headerName: 'Detalhe erro', flex: 1, minWidth: 150 },
    { field: 'createdAt', headerName: 'Data de envio', flex: 1, minWidth: 180, valueGetter: (params) => (new Date(params.row.createdAt).toLocaleString("pt-BR", { timeZone: 'America/Sao_Paulo' })) },
    { field: 'id', headerName: 'Id', flex: 1, minWidth: 150 },
  ];

  // function handleChange(e) {
  //   const { name, value } = e.target;

  //   setDataValue({
  //     ...dataValue,
  //     [name]: value,
  //   });
  // }

  const handleSetModalConexaoDeletada = () => {
    setModalConexaoDeletada(!modalConexaoDeletada);
  };

  useEffect(() => {
    getAlunos();
  }, [user]);

  const removeConexao = () => {
    VinculoApi.removeLink(user, conexaoDeletada)
      .then(() => {
        handleSetModalConexaoDeletada();
        handleSetModalDeletar();
        getAlunos();
        return;
      })
      .catch((error) => {
        handleSetModalDeletar();
        toast.error(error.response.data.error);
      });
  };

  const resetaSenha = async () => {
    handleSetModalResetar();
  }

  const handleExportEmails = async () => {
    await UsersApi.deleteNotifications(selectionModel);
    
    await getAlunos();
  }

  return (
    <>
      <TemporaryDrawer />
      <ModalResetarSenha 
        modalOpen={modalResetar}
        setModalOpen={handleSetModalResetar}
        onSuccess={resetaSenha}
        usuarios={rows.filter(x => selectionModel.includes(x._id) ).flatMap(x => x.pushToken)}
      />
      <ModalDeletar
        modalOpen={modalDeletar}
        setModalOpen={handleSetModalDeletar}
        onSuccess={removeConexao}
        handleConfirm={handleSetModalConexaoDeletada}
      />
      <ModalConexaoDeletada
        modalOpen={modalConexaoDeletada}
        setModalOpen={handleSetModalConexaoDeletada}
      />
      <ModalUpgradePlano
        modalOpen={modalPlano}
        setModalOpen={handleSetModalPlano}
      />
      <Container sx={{ maxWidth: '100vw' }}>
        {/* <Grid container sx={{ justifyContent: 'center', pt: 10 }}>
          <Card
            sx={{
              minWidth: 275,
              pl: 2,
              pr: 2,
              width: 1200,
              borderRadius: 5,
              backgroundColor: '#0E100F'
            }}
            variant="elevation"
          >
            <CardContent>
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item display={'flex'} xs={12}>
                  <Typography
                    color={'#ff66c4'}
                    variant="h5"
                    component="div"
                    sx={{ mr: 1 }}
                  >
                    Adicionar novo aluno
                  </Typography>
                  <ClickAwayListener onClickAway={() => { }}>
                    <Tooltip
                      title={
                        'Adicione alunos abaixo.'
                      }
                      open={tooltipAdicionar}
                      onClick={handleChangeTooltipAdicionar}
                      onMouseOver={() => setTooltipAdicionar(true)}
                      onMouseLeave={() => setTooltipAdicionar(false)}
                    >
                      <HelpOutlineIcon
                        color="success"
                        fontSize="12"
                      ></HelpOutlineIcon>
                    </Tooltip>
                  </ClickAwayListener>
                </Grid>
              </Grid>

              <>
                <Typography variant="body2" sx={{ color: '#bababa', mb: 2 }}>
                  Insira abaixo o e-mail do aluno para iniciar o cadastro.
                </Typography>
                <TextField
                  color="success"
                  InputLabelProps={{ style: { color: '#bababa' } }}
                  sx={{ backgroundColor: '#1C1F1E', input: { color: 'white' } }}
                  size="small"
                  type="email"
                  id="emailTest"
                  name={'emailTest'}
                  label="exemplo@email.com.br"
                  autoComplete="nope"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailTest}
                  error={formik.touched.emailTest && Boolean(formik.errors.emailTest)}
                  fullWidth
                />
              </>
            </CardContent>
            <CardActions
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pl: 2,
                pb: 4,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#ff66c4',
                  fontWeight: 'bold',
                  padding: 1,
                  width: 170,
                  ':hover': { background: '#003F06' },
                  ':disabled': {
                    backgroundColor: '#1C1F1E',
                    color: '#727272'
                  }
                }}
                disabled={
                  (formik.errors.emailTest || formik.values.emailTest === ''
                    ? true
                    : false) || loading
                }
                size="small"
                color="primary"
                variant="contained"
                onClick={() => addStudent(formik.values.emailTest)}
              >
                {loading ? <CircularProgress size={26} /> : 'ADICIONAR ALUNO'}
              </Button>
            </CardActions>
          </Card>
        </Grid> */}
        <Grid container sx={{ justifyContent: 'center', pt: 10 }}>
          <Card
            sx={{
              padding: 2,
              paddingTop: 3,
              backgroundColor: '#0E100F',
              width: '1471px',
              borderRadius: 5,
            }}
          >
            <Grid container>
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item display={'flex'} xs={12}>
                  <Typography
                    color="#ff66c4"
                    variant="h5"
                    component="div"
                    sx={{ mr: 1 }}
                  >
                    Lista de usuarios
                  </Typography>
                  <ClickAwayListener onClickAway={() => { }}>
                    <Tooltip
                      title={
                        'Aqui você terá uma lista de todos os seus clientes! Quer encontrar um cliente mais rápido? Utilize o filtro abaixo =)'
                      }
                      open={tooltipRelacao}
                      onClick={handleChangeTooltipRelacao}
                      onMouseOver={() => setTooltipRelacao(true)}
                      onMouseLeave={() => setTooltipRelacao(false)}
                    >
                      <HelpOutlineIcon
                        color="success"
                        fontSize="12"
                      ></HelpOutlineIcon>
                    </Tooltip>
                  </ClickAwayListener>
                </Grid>
              </Grid>
              <Grid spacing={1} container style={{ marginTop: 20 }}>
                <Grid item xs={12} md={4} sm={12}>
                  <TextField
                    InputLabelProps={{ style: { color: '#bababa' } }}
                    color="success"
                    onChange={(e) => {
                      setFilterText2(e.target.value);
                    }}
                    label="Filtrar por email"
                    sx={{
                      backgroundColor: '#1C1F1E',
                      input: {
                        '&::placeholder': {
                          color: '#727272',
                          opacity: 1,
                        },
                      },
                      '& .MuiInputBase-root': {
                        '& input': {
                          color: '#fff',
                        },
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Filtrar por email"
                  />
                </Grid>
                
                <Grid item xs={12} md={3}>
            
          </Grid>
          <Grid item xs={12} md={3}>
            
          </Grid>
          <Grid item xs={12} md={3}>
            
          </Grid>
          <Grid xs={12} md={12} mt={5}>
                <Button
                  color="success"
                  onClick={handleExportEmails}
                  variant="outlined"
                  disabled={!selectionModel.length}
                  sx={{ color: '#ff66c4', borderColor: '#ff66c4', fontWeight: 'bold', mr: 1, ':hover': { color: '#fff', background: '#ff66c4' } }}
                >
                  Deletar selecionados
                </Button>
            </Grid>
          
                {/* <Grid item xs={12} md={2} sm={12}>
                  <TextField
                    id="dataInicial"
                    color="success"
                    InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
                    label="Adicionado depois de"
                    name="dataInicial"
                    fullWidth
                    onChange={handleChange}
                    type="date"
                    value={dataValue.dataInicial}
                    variant="outlined"
                    InputProps={{style: { color: '#fff' }}}
                    sx={{
                      backgroundColor: '#1C1F1E', input: { color: 'white' },
                      '& .MuiInputBase-root': {
                        '& input': {
                          color: '#bababa',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2} sm={12}>
                  <TextField
                    aria-label="Fim"
                    id="dataFinal"
                    color="success"
                    InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
                    label="Adicionar antes de"
                    name="dataFinal"
                    onChange={handleChange}
                    type="date"
                    fullWidth
                    value={dataValue.dataFinal}
                    variant="outlined"
                    sx={{
                      backgroundColor: '#1C1F1E', input: { color: 'white' },
                      '& .MuiInputBase-root': {
                        '& input': {
                          color: '#bababa',
                        },
                      },
                    }}
                  />
                </Grid> */}
              </Grid>
              <Grid container style={{ marginTop: 30, backgroundColor: '#1C1F1E' }}>
                <DataGrid
                  sx={{
                    '.MuiTablePagination-toolbar': {
                      color: '#727272'
                    },
                    border: '1px solid #727272',
                    height: 600,
                    fontSize: '14px',
                    width: '100%',
                    color: '#727272',
                    '& ::-webkit-scrollbar': {
                      width: 3,
                      height: 5,
                    },
                  }}
                  rows={rows
                    .filter((text) =>
                      text.email
                        .toString()
                        .toLowerCase()
                        .includes(filterText2.toString().toLowerCase())
                    )
                    // .filter((text) => {
                    //   if (!dataValue.dataInicial) {
                    //     return true;
                    //   }
                    //   const createdDate = DateTime.fromISO(text.createdAt);
                    //   const initialDate = DateTime.fromFormat(
                    //     dataValue.dataInicial,
                    //     'yyyy-MM-dd'
                    //   );
                    //   return dataValue.dataInicial && initialDate < createdDate;
                    // })
                    // .filter((text) => {
                    //   if (!dataValue.dataFinal) {
                    //     return true;
                    //   }
                    //   const createdDate = DateTime.fromISO(text.createdAt);
                    //   const finalDate = DateTime.fromFormat(
                    //     dataValue.dataFinal,
                    //     'yyyy-MM-dd'
                    //   );
                    //   return dataValue.dataFinal && createdDate < finalDate;
                    // })
                  }
                  getRowId={(row) => row._id}
                  columns={columns}
                  pageSize={50}
                  selectionModel={selectionModel}
                  onSelectionModelChange={(item) => {
                    console.log(item);
                    setSelectionModel(item)
                  }}
                  checkboxSelection
                  rowsPerPageOptions={[10,20, 50, 100]}
                  components={{
                    NoRowsOverlay: () => (
                      <div style={{ display: 'flex', height:'100%', alignItems:'center', justifyContent:'center' }}>
                        Nenhum resultado encontrado
                      </div>
                    ),
                    NoResultsOverlay: () => (
                      <div style={{ display: 'flex', height:'100%', alignItems:'center', justifyContent:'center' }}>
                         Nenhum resultado encontrado
                      </div>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
      <AlertDialog
        style={{ background: 'white' }}
        isOpen={showAlert}
        handleClose={() => setShowAlert(false)}
        whatAlert={whatAlert}
        loading={loading}
        handleRegisterNew={handleRegisterNew}
      />
    </>
  );
};

export default Notifications;
