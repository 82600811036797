import { useContext } from 'react';
import { ProviderContext } from '../context';

const LOCAL_STORAGE_KEY = 'user';
const LOCAL_STORAGE_ROLE = 'role';
const LOCAL_STORAGE_PLAN = 'plan';

const useStore = () => {
  const { state, setState } = useContext(ProviderContext);

  const setUser = (user) => {
    setState({ ...state, user });
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user));
  };

  const setRole = (role) => {
    setState({ ...state, role });
    localStorage.setItem(LOCAL_STORAGE_ROLE, JSON.stringify(role));
  };

  const setPlan = (plan) => {
    setState({ ...state, plan });
    localStorage.setItem(LOCAL_STORAGE_PLAN, JSON.stringify(plan));
  }

  const getUser = () => {
    if (state.user && state.role) {
      return { user: state.user, role: state.role, plan: state.plan };
    }

    const userJson = localStorage.getItem(LOCAL_STORAGE_KEY);
    const roleJson = localStorage.getItem(LOCAL_STORAGE_ROLE);
    let planJson = localStorage.getItem(LOCAL_STORAGE_PLAN);

    if(planJson == 'undefined') planJson = '';

    if (userJson && roleJson) {
      setState({ user: JSON.parse(userJson), role: JSON.parse(roleJson), plan: planJson ? JSON.parse(planJson) : '' });

      return { user: state.user, role: state.role, plan: state.plan };
    }

    return { user: null, role: null, plan: null };
  };

  const removeUser = () => {
    setState({ user: null, role: null, plan: null });
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_ROLE);
    localStorage.removeItem(LOCAL_STORAGE_PLAN);
  };

  return { state, setUser, setRole, setPlan, getUser, removeUser };
};

export { useStore };
