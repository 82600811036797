import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UsersApi from '../../services/users';
import {
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const steps = ['Dados', 'Serviço', 'Horários'];

const phoneMask = (value) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

const daysWeekly = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];
const daysTercaSabado = ['Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
const daysSegundaSabado = [
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];
console.log(daysWeekly);

const Agendar = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [data, setData] = useState(new Date());
  const [datas, setDatas] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [hours, setHours] = useState([]);
  const [hoursToSelect, setHoursToSelect] = useState([]);
  const [timezone, setTimezone] = useState('America/Sao_Paulo');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [userToUpdate, setUserToUpdate] = useState();
  const [lancamentos, setLancamentos] = useState([]);
  const [dadoAgendado, setDadoAgendado] = useState();
  const [intervalo, setIntervalo] = useState('');
  const [isNotValid, setIsNotValid] = useState(true);

  const loadData = async () => {
    try {
      const mail = searchParams.get('key');
      const response = await UsersApi.getByEmail(mail);
      const responsePremium = await UsersApi.getPremiumData(mail);
      setLoading(false);
      console.log(response);
      setServicos(JSON.parse(response[0].servicos));
      setAgendas(JSON.parse(response[0].agendas));
      const hoursParsed = JSON.parse(response[0].hours);
      setHours(hoursParsed);
      setClientes(JSON.parse(response[0].clientes));
      setTimezone(response[0].timezone);
      setUserToUpdate(response[0]);
      setLancamentos(JSON.parse(response[0].lancamentos));
      const intervaloParsed = hoursParsed.filter((x) => x.text);
      console.log(intervaloParsed);
      // setIntervalo(intervaloParsed[0].value)
      setIntervalo(
        intervaloParsed.length ? intervaloParsed[0].value.toString() : '60'
      );
      if (
        responsePremium[0].isPremium &&
        responsePremium[0]?.daysLeftPremium > 0
      ) {
        setIsNotValid(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [view, setView] = React.useState('');
  const [servico, setServico] = React.useState('');

  useEffect(() => {
    const service = servicos.find((x) => x.name === servico);
    // setHoursToSelect([])
    console.log(service, hoursToSelect);

    if (service) {
      const agendasNaData = agendas.filter(
        (x) =>
          new Date(x.date).toLocaleDateString('pt-BR', {
            timeZone: timezone,
          }) === data.toLocaleDateString('pt-BR', { timeZone: timezone })
      );
      console.log(agendasNaData);

      const hoursDefault = hours.filter((x) => x.checked);
      console.log(hoursDefault);
      // if(hoursDefault[0]?.default){
      // const splited = hoursDefault[0].day.split(' à ')
      // const dayStart = splited[0];
      // const dayEnd = splited[1]
      // console.log(dayStart, dayEnd)
      let initialHourSplited;
      let endHourSplited;
      if (hoursDefault[0]?.default) {
        initialHourSplited = hoursDefault[0].initialHour.split(':');
        endHourSplited = hoursDefault[0].finalHour.split(':');
      } else {
        const findDate = hoursDefault.find(
          (x) => x.day == daysWeekly[data.getDay()]
        );
        initialHourSplited = findDate.initialHour.split(':');
        endHourSplited = findDate.finalHour.split(':');
      }

      console.log(endHourSplited, initialHourSplited);
      const dataCopy = new Date(JSON.parse(JSON.stringify(data)));
      const dataCopyEnd = new Date(JSON.parse(JSON.stringify(data)));

      dataCopy.setHours(initialHourSplited[0]);
      dataCopy.setMinutes(initialHourSplited[1]);
      dataCopy.setSeconds(0);
      dataCopyEnd.setHours(endHourSplited[0]);
      dataCopyEnd.setMinutes(endHourSplited[1]);
      dataCopyEnd.setSeconds(0);

      console.log(dataCopy, dataCopyEnd);

      const difference = Math.abs(dataCopyEnd.getTime() - dataCopy.getTime());
      const hourDifference = difference / 1000 / 3600;

      console.log(hourDifference);

      const newDUration = service.duration.split(/(?:h|min)+/);
      console.log(newDUration);

      // const dateToCopy = new Date(JSON.parse(JSON.stringify(dataCopy)))
      const datesLiberados = [];

      // for(let x = 0; x < hourDifference; x++){
      //     const isMin = service.duration.includes('min')
      //     const dateToAdd = (x === 0 || !isMin) ? new Date(JSON.parse(JSON.stringify(dateToCopy))) : new Date(JSON.parse(JSON.stringify(datesLiberados[datesLiberados.length - 1].end)));
      //     const dataToAddInit =  x === 0 ? new Date(JSON.parse(JSON.stringify(dateToAdd))) : new Date(JSON.parse(JSON.stringify(datesLiberados[datesLiberados.length - 1].end)))

      //     if(newDUration.length == 2){
      //         // dataToAddInit.setHours((dateToAdd.getHours() + x))
      //         if(service.duration.includes('h')){
      //             dateToAdd.setHours((dateToAdd.getHours() + x)+Number(newDUration[0]));
      //         }else{
      //             dateToAdd.setMinutes(dateToAdd.getMinutes()+ (30))
      //         }
      //     }else{
      //         console.log(Number(newDUration[0]) + 0.5)
      //         dateToAdd.setHours((dateToAdd.getHours())+ (Number(newDUration[0]) + 0.5));
      //         dateToAdd.setMinutes(dateToAdd.getMinutes()+ (30))
      //         console.log(dateToAdd, 'ADDIO')
      //     }
      //     datesLiberados.push({init: dataToAddInit, end: dateToAdd });
      // }

      const sameDay = (d1, d2) => {
        return (
          d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate() &&
          d1.getHours() === d2.getHours() &&
          d1.getMinutes() === d2.getMinutes()
        );
      };

      const dataInit = new Date(JSON.parse(JSON.stringify(dataCopy)));
      const datasDisponiveis = [dataCopy];
      while (dataInit <= dataCopyEnd && !sameDay(dataInit, dataCopyEnd)) {
        if (intervalo == '30') {
          if (dataInit.getMinutes() == 0) {
            dataInit.setMinutes(30);
          } else {
            dataInit.setHours(dataInit.getHours() + 1);
            dataInit.setMinutes(0);
          }
        } else if (intervalo == '15') {
          if (dataInit.getMinutes() == 0) {
            dataInit.setMinutes(15);
          } else if (dataInit.getMinutes() == 15) {
            dataInit.setMinutes(30);
          } else if (dataInit.getMinutes() == 30) {
            dataInit.setMinutes(45);
          } else {
            dataInit.setHours(dataInit.getHours() + 1);
            dataInit.setMinutes(0);
          }
        } else if (intervalo == '10') {
          if (dataInit.getMinutes() == 0) {
            dataInit.setMinutes(10);
          } else if (dataInit.getMinutes() == 10) {
            dataInit.setMinutes(20);
          } else if (dataInit.getMinutes() == 20) {
            dataInit.setMinutes(30);
          } else if (dataInit.getMinutes() == 30) {
            dataInit.setMinutes(40);
          } else if (dataInit.getMinutes() == 40) {
            dataInit.setMinutes(50);
          } else {
            dataInit.setHours(dataInit.getHours() + 1);
            dataInit.setMinutes(0);
          }
        } else if (intervalo == '1') {
          dataInit.setHours(dataInit.getHours() + 1);
          dataInit.setMinutes(0);
        } else if (intervalo == '3') {
          dataInit.setHours(dataInit.getHours() + 3);
          dataInit.setMinutes(0);
        }
        datasDisponiveis.push(new Date(JSON.parse(JSON.stringify(dataInit))));
      }

      // const horariosAgendaData = []
      // agendasNaData.forEach(agendaNaData => {
      //     const dataHora = new Date(JSON.parse(JSON.stringify(agendaNaData.date)))
      //     const horaInicio = new Date(JSON.parse(JSON.stringify(agendaNaData.hour)))

      //     dataHora.setHours(horaInicio.getHours())
      //     dataHora.setMinutes(horaInicio.getMinutes())

      //     const dataHoraFim = new Date(JSON.parse(JSON.stringify(dataHora)))

      //     let horaFim
      //     if(agendaNaData?.hourEnd){
      //         horaFim = new Date(JSON.parse(JSON.stringify(agendaNaData.hourEnd)))
      //         console.log(horaFim);
      //         dataHoraFim.setHours(horaFim.getHours())
      //         dataHoraFim.setMinutes(horaFim.getMinutes())
      //     }
      //     horariosAgendaData.push({ init: dataHora, end: dataHoraFim })
      // })

      console.log(datesLiberados);

      // const verificaSeEstaNaData = (dataLiberada) => {
      //     let isAgendado = true;
      //     agendasNaData.forEach(agendaNaData => {
      //         if(!isAgendado) return;
      //         const dataHora = new Date(JSON.parse(JSON.stringify(agendaNaData.date)))
      //         const horaInicio = new Date(JSON.parse(JSON.stringify(agendaNaData.hour)))

      //         dataHora.setHours(horaInicio.getHours())
      //         dataHora.setMinutes(horaInicio.getMinutes())

      //         const dataHoraFim = new Date(JSON.parse(JSON.stringify(dataHora)))

      //         let horaFim
      //         if(agendaNaData?.hourEnd){
      //             horaFim = new Date(JSON.parse(JSON.stringify(agendaNaData.hourEnd)))
      //             console.log(horaFim);
      //             dataHoraFim.setHours(horaFim.getHours())
      //             dataHoraFim.setMinutes(horaFim.getMinutes())
      //         }

      //         if((dataHora < dataLiberada.init && dataHoraFim > dataLiberada.end) || (sameDay(dataHora, dataLiberada.init) && dataHora <= dataLiberada.end) || (dataHora >= dataLiberada.init && dataHora < dataLiberada.end && !sameDay(dataHora, dataLiberada.end)) || (dataHoraFim >= dataLiberada.init && dataHoraFim <= dataLiberada.end)){
      //             isAgendado = false;
      //             return false;
      //         }
      //     })

      //     return isAgendado;
      // }

      const verificaSeEstaNaData2 = (dataLiberada) => {
        const dataFinal = new Date(JSON.parse(JSON.stringify(dataLiberada)));
        if (newDUration.length == 2) {
          // dataToAddInit.setHours((dateToAdd.getHours() + x))
          if (service.duration.includes('h')) {
            dataFinal.setHours(dataFinal.getHours() + Number(newDUration[0]));
          } else {
            dataFinal.setMinutes(dataFinal.getMinutes() + 30);
          }
        } else {
          console.log(Number(newDUration[0]) + 0.5);
          dataFinal.setHours(
            dataFinal.getHours() + (Number(newDUration[0]) + 0.5)
          );
          dataFinal.setMinutes(dataFinal.getMinutes() + 30);
        }
        let isAgendado = true;
        agendasNaData.forEach((agendaNaData) => {
          if (!isAgendado) return;
          const dataHora = new Date(
            JSON.parse(JSON.stringify(agendaNaData.date))
          );
          const horaInicio = new Date(
            JSON.parse(JSON.stringify(agendaNaData.hour))
          );

          dataHora.setHours(horaInicio.getHours());
          dataHora.setMinutes(horaInicio.getMinutes());

          const dataHoraFim = new Date(JSON.parse(JSON.stringify(dataHora)));

          let horaFim;
          if (agendaNaData?.hourEnd) {
            horaFim = new Date(
              JSON.parse(JSON.stringify(agendaNaData.hourEnd))
            );
            console.log(horaFim);
            dataHoraFim.setHours(horaFim.getHours());
            dataHoraFim.setMinutes(horaFim.getMinutes());
          }

          if (
            (dataHora < dataLiberada && dataHoraFim > dataFinal) ||
            (sameDay(dataHora, dataLiberada) && dataHora <= dataFinal) ||
            (dataHora >= dataLiberada &&
              dataHora < dataFinal &&
              !sameDay(dataHora, dataFinal)) ||
            (dataHoraFim >= dataLiberada && dataHoraFim <= dataFinal)
          ) {
            isAgendado = false;
            return false;
          }
        });

        return isAgendado;
      };

      // agendasNaData.forEach(agendaNaData => {
      // const horariosLiberados = datesLiberados.filter(datLiberada => verificaSeEstaNaData(datLiberada))
      // })
      // console.log(horariosLiberados)
      const horariosLiberados2 = datasDisponiveis.filter((dataDisponivel) =>
        verificaSeEstaNaData2(dataDisponivel)
      );

      console.log(datasDisponiveis, 'AQUI MANOOOOOO');
      console.log(horariosLiberados2, 'LIBERADO 2');
      // console.log(horariosAgendaData, 'AGENDA DOS GURIs')
      setHoursToSelect(horariosLiberados2);

      // if(newDUration.length == 2){
      //     if(duration.includes('h')){
      //       newHour.setHours(newHour.getHours()+Number(newDUration[0]));
      //       setSelectedHourEnd(newHour)
      //     }else{
      //       newHour.setMinutes(newHour.getMinutes()+ (30))
      //       setSelectedHourEnd(newHour)
      //     }
      // }else{
      //     newHour.setHours(newHour.getHours()+ (Number(newDUration[0]) + 0.5));
      //     newHour.setMinutes(newHour.getMinutes()+ (30))
      //     setSelectedHourEnd(newHour)
      // }

      // }
    }
  }, [servico, data]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!hours.length) return;
    const dates = [];
    for (var i = 0; i < 60; i++) {
      const hoursUser = hours.filter((x) => x.checked);

      console.log(hoursUser, 'AQUI');

      const addDay = new Date();
      addDay.setDate(addDay.getDate() + i);
      if (hoursUser[0].day === 'Terça à Sábado') {
        if (daysTercaSabado.includes(daysWeekly[addDay.getDay()])) {
          dates.push(addDay);
        }
      }
      if (hoursUser[0].day === 'Segunda à Sábado') {
        if (daysSegundaSabado.includes(daysWeekly[addDay.getDay()])) {
          dates.push(addDay);
        }
      }
      if (!hoursUser[0]?.default) {
        const daysValid = hoursUser.flatMap((x) => x.day);
        if (daysValid.includes(daysWeekly[addDay.getDay()])) {
          dates.push(addDay);
        }
      }
    }
    if (dates.length) setData(dates[0]);
    console.log(dates);
    setDatas(dates);
  }, [hours]);

  const handleChange = (event, nextView) => {
    console.log(nextView, 'AQUIDDDDD');
    setView(nextView);
  };

  const handleChangeServico = (event, nextView) => {
    setServico(nextView);
  };

  const handlePhone = (value) => {
    setPhone(value);
    localStorage.setItem('phone', value);
  };

  const handleName = (value) => {
    setName(value);
    localStorage.setItem('name', value);
  };

  useEffect(() => {
    const nome = localStorage.getItem('name');
    const telefone = localStorage.getItem('phone');

    if (nome && telefone) {
      setName(nome);
      setPhone(telefone);
      setActiveStep(1);
    }
  }, []);

  const generateId = () => {
    const caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * caracteres.length);
      id += caracteres[randomIndex];
    }
    return id;
  };

  const handleDadosAgendamento = () => {
    const service = servicos.find((x) => x.name === servico);
    console.log(hoursToSelect[view], service);
    const dataInit = hoursToSelect[view];

    const ok = window.confirm(
      'Confirmar agendamento? \n' +
        'Data: ' +
        dataInit.toLocaleDateString('pt-BR', timezone) +
        '-' +
        dataInit.toLocaleTimeString('pt-BR', timezone) +
        '\n' +
        'Serviço: ' +
        service.name
    );
    if (!ok) return;
    const newDUration = service.duration.split(/(?:h|min)+/);
    const dateEnd = new Date(JSON.parse(JSON.stringify(dataInit)));
    if (newDUration.length == 2) {
      // dataToAddInit.setHours((dateToAdd.getHours() + x))
      if (service.duration.includes('h')) {
        dateEnd.setHours(dateEnd.getHours() + Number(newDUration[0]));
      } else {
        dateEnd.setMinutes(dateEnd.getMinutes() + 30);
      }
    } else {
      console.log(Number(newDUration[0]) + 0.5);
      dateEnd.setHours(dateEnd.getHours() + (Number(newDUration[0]) + 0.5));
      dateEnd.setMinutes(dateEnd.getMinutes() + 30);
    }
    const obj = {
      date: dataInit,
      hour: dataInit,
      hourEnd: dateEnd,
      service: service,
    };

    //LAST RELEASE
    const item = {
      category: service?.name,
      calendar: dataInit,
      value: service?.price,
      received: true,
      id: generateId(),
      formOfPayment: '',
    };
    console.log(item);

    const cleanPhone = (pphone) =>
      pphone.replace(/\D/g, '').replace('55', '').replace('+55', '');

    let responseClient = clientes
      .filter((x) => x.cellphone)
      .find(
        (x) =>
          cleanPhone(phone).includes(cleanPhone(x.cellphone)) ||
          cleanPhone(x.cellphone).includes(cleanPhone(phone))
      );
    const clientesAdd = JSON.parse(JSON.stringify(clientes));
    if (!responseClient) {
      responseClient = {
        id: generateId(),
        name: name,
        address: '',
        cellphone: phone,
        active: true,
      };

      clientesAdd.push(responseClient);
      setClientes(clientesAdd);
    }
    console.log(responseClient);
    const agendamento = {
      date: dataInit,
      hour: dataInit,
      hourEnd: dateEnd,
      observation: '',
      title: '',
      releaseId: item.id,
      releaseData: item,
      serviceId: service?.id,
      clientId: responseClient?.id,
      repeat: 'unico',
      id: generateId(),
    };

    const agendasAdd = JSON.parse(JSON.stringify(agendas));
    agendasAdd.push(agendamento);

    const lancamentosAdd = JSON.parse(JSON.stringify(lancamentos));

    lancamentosAdd.push(item);

    console.log(agendamento);
    const newUser = JSON.parse(JSON.stringify(userToUpdate));
    newUser.agendas = JSON.stringify(agendasAdd);
    newUser.clientes = JSON.stringify(clientesAdd);
    newUser.lancamentos = JSON.stringify(lancamentosAdd);

    UsersApi.postUsuario(newUser);

    setDadoAgendado(obj);

    localStorage.setItem('@agendado', JSON.stringify(obj));
    setActiveStep(3);

    const messages = [];
    messages.push({
      to: newUser.pushToken,
      title: 'Novo cliente agendado!',
      body: `Cliente: ${name} - ${dataInit.toLocaleDateString(
        'pt-BR',
        timezone
      )} - ${dataInit.toLocaleTimeString('pt-BR', timezone)} \n ${
        service.name
      }`,
    });

    UsersApi.sendNotifications(messages);
  };

  useEffect(() => {
    const dataAgendar = JSON.parse(localStorage.getItem('@agendado'));
    if (dataAgendar) {
      if (new Date(dataAgendar.date) >= new Date()) {
        setDadoAgendado(dataAgendar);
        setActiveStep(3);
        console.log(dadoAgendado);
      }
    }
  }, []);

  const step1 = () => {
    return (
      <>
        <h3 style={{ color: '#fff' }}>Preencha seus dados</h3>
        <TextField
          inputProps={{ maxLength: 30 }}
          InputLabelProps={{ style: { color: '#bababa' } }}
          value={name}
          onChange={(event) => handleName(event.target.value)}
          color="success"
          sx={{
            backgroundColor: '#1C1F1E',
            input: {
              '&::placeholder': {
                color: '#727272',
                opacity: 1,
              },
            },
            '& .MuiInputBase-root': {
              '& input': {
                color: '#fff',
              },
            },
          }}
          variant="outlined"
          style={{ marginTop: 10, color: '#fff' }}
          fullWidth
          id="outlined-basic"
          label="Nome"
        />
        <TextField
          inputProps={{ maxLength: 16 }}
          value={phoneMask(phone)}
          onChange={(event) => handlePhone(event.target.value)}
          InputLabelProps={{ style: { color: '#bababa' } }}
          color="success"
          sx={{
            backgroundColor: '#1C1F1E',
            input: {
              '&::placeholder': {
                color: '#727272',
                opacity: 1,
              },
            },
            '& .MuiInputBase-root': {
              '& input': {
                color: '#fff',
              },
            },
          }}
          variant="outlined"
          style={{ marginTop: 10 }}
          fullWidth
          id="outlined-basic"
          label="Celular"
        />
        <Button
          disabled={!name || !phone}
          sx={{
            color: '#fff',
            background: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          color="success"
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(1)}
          variant="contained"
        >
          Continuar
        </Button>
      </>
    );
  };

  const step2 = () => {
    return (
      <div>
        <h3 style={{ color: '#fff' }}>Selecione o Serviço</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ToggleButtonGroup
            // sx={{ flexWrap: "wrap"}}
            orientation="vertical"
            value={servico}
            exclusive
            onChange={handleChangeServico}
          >
            {servicos
              .filter((x) => x.price > 0)
              .map((serv, index) => {
                return (
                  <ToggleButton
                    key={index}
                    style={{ marginTop: 10 }}
                    value={serv.name}
                    aria-label="list"
                  >
                    {serv.icon} {serv.name} -{' '}
                    {serv.price.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </ToggleButton>
                );
              })}
            {/* <ToggleButton style={{ marginTop: 10 }} value="list" aria-label="list">
                    Mao e pe
                </ToggleButton>
                <ToggleButton style={{ marginTop: 10 }}  value="module" aria-label="module">
                    Esmaltacao em gel
                </ToggleButton> */}
          </ToggleButtonGroup>
        </div>
        <Button
          disabled={!servico}
          sx={{
            color: '#fff',
            background: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          color="success"
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(2)}
          variant="contained"
        >
          Continuar
        </Button>
        <Button
          sx={{
            color: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(0)}
          variant="outlined"
        >
          Voltar
        </Button>
      </div>
    );
  };

  const step3 = () => {
    return (
      <div>
        <h3 style={{ color: '#fff' }}>Selecione o Horário</h3>
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            style={{ color: '#ff66c4' }}
          >
            Data
          </InputLabel>
          <Select
            color="success"
            displayEmpty
            value={data.toLocaleDateString('pt-BR', { timeZone: timezone })}
            label="Data"
            onChange={(e) => {
              setData(e.target.value);
            }}
            placeholder="test"
            renderValue={(selected) => {
              if (selected === undefined || selected === ' ') {
                return (
                  <Typography color={'#727272'}>Tipo selecionado</Typography>
                );
              }
              return selected;
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              backgroundColor: '#1C1F1E',
              color: '#fff',
              input: {
                color: '#727272',
                '&::placeholder': {
                  color: '#727272',
                  opacity: 1,
                },
              },
            }}
          >
            {datas.map((x, index) => {
              return (
                <MenuItem key={index} value={x}>{`${x.toLocaleDateString(
                  'pt-BR',
                  { timeZone: timezone }
                )} - ${daysWeekly[x.getDay()]}`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {!hoursToSelect?.length ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: '#fff',
              fontSize: 28,
              textAlign: 'center',
            }}
          >
            Não há horários disponíveis para o dia selecionado
          </div>
        ) : (
          hoursToSelect.map((x, index) => {
            if ((index + 1) % 2 === 0) {
              return (
                <div
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <ToggleButtonGroup
                    // sx={{ flexWrap: "wrap"}}
                    orientation="horizontal"
                    value={view}
                    exclusive
                    onChange={handleChange}
                  >
                    <ToggleButton
                      style={{ marginTop: 10 }}
                      value={index - 1}
                      aria-label="list"
                    >
                      {hoursToSelect[index - 1]
                        .toLocaleTimeString()
                        .substring(0, 5)}
                    </ToggleButton>
                    <ToggleButton
                      style={{ marginTop: 10, marginLeft: 10 }}
                      value={index}
                      aria-label="module"
                    >
                      {x.toLocaleTimeString().substring(0, 5)}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              );
            } else if (index === hoursToSelect.length - 1) {
              return (
                <div
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <ToggleButtonGroup
                    // sx={{ flexWrap: "wrap"}}
                    orientation="horizontal"
                    value={view}
                    exclusive
                    onChange={handleChange}
                  >
                    <ToggleButton
                      style={{ marginTop: 10, marginLeft: 10 }}
                      value={index}
                      aria-label="module"
                    >
                      {x.toLocaleTimeString().substring(0, 5)}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              );
            }
          })
        )}
        <Button
          disabled={!view && view !== 0}
          sx={{
            color: '#fff',
            background: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          color="success"
          style={{ marginTop: 10 }}
          fullWidth
          onClick={handleDadosAgendamento}
          variant="contained"
        >
          Continuar
        </Button>
        <Button
          sx={{
            color: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(1)}
          variant="outlined"
        >
          Voltar
        </Button>
      </div>
    );
  };

  const stepConcluded = () => {
    return (
      <div
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <div>
          <h3 style={{ color: '#fff' }}>Agendamento realizado com sucesso!</h3>
        </div>
        <div>
          <CheckCircleIcon style={{ fontSize: 100 }} />
        </div>
        <div>
          <h3 style={{ color: '#fff' }}>
            Horario:{' '}
            {new Date(dadoAgendado.date).toLocaleDateString('pt-BR', timezone)}{' '}
            -{' '}
            {new Date(dadoAgendado.date).toLocaleTimeString('pt-BR', timezone)}
          </h3>
          <h3 style={{ color: '#fff' }}>
            Serviço: {dadoAgendado.service.name}
          </h3>
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: 20 }}>
      {loading ? (
        <CircularProgress />
      ) : isNotValid ? (
        <>
          <h5 style={{ color: '#fff' }}>
            Link de agendamento online nao disponivel. Renovacao necessaria.
          </h5>
        </>
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel style={{ color: '#fff !important' }}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 0
            ? step1()
            : activeStep === 1
            ? step2()
            : activeStep === 2
            ? step3()
            : stepConcluded()}
        </>
      )}
    </div>
  );
};

export default Agendar;
