import React, { useState } from 'react';
import { Modal, Button, Box, Typography, Grid, TextField } from '@mui/material';
import UsersApi from '../../../../services/users'

const ModalResetarSenha = ({ modalOpen, setModalOpen, onSuccess, usuarios }) => {
  const [titulo, setTitulo] = useState('');
  const [mensagem, setMensagem] = useState('')

  const sendNotifications = async () => {
    console.log(usuarios);
    const messages = []
    usuarios.forEach((x) => {
      messages.push({
        to: x,
        title: titulo,
        body: mensagem
      })
    })

    await UsersApi.sendNotifications(messages);
  }

  return (
    <Modal
      fullWidth
      open={modalOpen}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          outline: 'none',
          p: 2,
          width: [240, 358, 442, 595],
          height: [300],
          backgroundColor: '#1C1F1E',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Typography variant="h5" sx={{ mb: 4, color: '#ff66c4' }}>
          Enviar notificação
        </Typography>
        <Grid spacing={1} container style={{ marginTop: 20 }}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    InputLabelProps={{ style: { color: '#bababa' } }}
                    color="success"
                    onChange={(e) => {
                      setTitulo(e.target.value);
                    }}
                    value={titulo}
                    label="Titulo"
                    sx={{
                      backgroundColor: '#1C1F1E',
                      input: {
                        '&::placeholder': {
                          color: '#727272',
                          opacity: 1,
                        },
                      },
                      '& .MuiInputBase-root': {
                        '& input': {
                          color: '#fff',
                        },
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Titulo"
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    InputLabelProps={{ style: { color: '#bababa' } }}
                    color="success"
                    onChange={(e) => {
                      setMensagem(e.target.value);
                    }}
                    value={mensagem}
                    label="Mensagem"
                    sx={{
                      backgroundColor: '#1C1F1E',
                      input: {
                        '&::placeholder': {
                          color: '#727272',
                          opacity: 1,
                        },
                      },
                      '& .MuiInputBase-root': {
                        '& input': {
                          color: '#fff',
                        },
                      },
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Mensagem"
                  />
                </Grid>
              </Grid>
        <Grid
          sx={{
            alignSelf: 'end',
          }}
        >
          <Button
            variant="outlined"
            onClick={setModalOpen}
            sx={{
              alignSelf: 'end',
              border: 'none',
              fontWeight: 'bold',
              width: 120,
              color: '#bababa',
            }}
          >
            VOLTAR
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onSuccess();
              const resposta = confirm(`Deseja mesmo enviar notificacao para ${usuarios.length} usuarios?`);
              if(resposta){
                console.log(resposta)
                sendNotifications()
              }
            }}
            disabled={!titulo || !mensagem}
            sx={{
              border: 'none',
              fontWeight: 'bold',
              width: 120,
              color: '#ff66c4',
            }}
          >
            ENVIAR
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalResetarSenha;
