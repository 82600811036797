import { Box, Button, Modal, Typography, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks';

const LogoutModal = ({ visible, setVisible }) => {
  const navigate = useNavigate();
  const store = useStore();

  const handleClose = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    store.removeUser();
    setVisible(true);
    navigate('/');
  };

  return (
    <Modal
      open={visible}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          p: 4,
          width: 300,
          height: 'auto',
          backgroundColor: '#1C1F1E',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color="#ff66c4"
          sx={{ textAlign: 'left' }}
          variant="h6"
          component="div"
        >
          Deseja realmente sair?
        </Typography>
        <Grid container sx={{ mt: 5, justifyContent: 'center' }}>
          <Button
            color="success"
            onClick={handleClose}
            variant="outlined"
            sx={{ fontWeight: 'bold', mr: 1, ':hover': { color: '#fff', background: '#003F06' } }}
          >
            Não
          </Button>
          <Button
            color="success"
            onClick={handleLogout}
            variant="contained"
            sx={{ fontWeight: 'bold', ml: 1, ':hover': { background: '#003F06' } }}
          >
            Sair
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
