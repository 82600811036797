import React, { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import IconePerfil from '../../assets/IconePerfil2.svg';
import logoMenu from '../../assets/agenda-rosa.png';

import MenuIcon from '@mui/icons-material/Menu';

// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import GroupIcon from '@mui/icons-material/Group';
// import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
// import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import DescriptionIcon from '@mui/icons-material/Description';
import { ListSubheader, Button, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutModal from './logout-modal';
import Config from '../../config'

export default function TemporaryDrawer() {
  const [state, setState] = useState({
    left: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleModalVisible = () => {
    setVisible(true);
  };

  const navigate = useNavigate();

  const routes = {
    treino: '/relacao-treinos',
    perfilPessoal: '/perfil',
    conexoes: '/conexoes',
    montagemTreino: '/montagem-treinos',
    plano: '/planos',
    exercicio: '/exercicios',
    tutorial: '/tutorial'
  };

  const roleStorage = localStorage.getItem('role');

  const role = JSON.parse(roleStorage);

  const planStorage = localStorage.getItem('plan')
  const plan = planStorage == 'undefined' || !planStorage ? '' : JSON.parse(planStorage);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (anchor, newOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: newOpen });
  };

  const handleNavigateTreinos = () => navigate('/feedback');

  const handleNavigateNotifications = () => navigate('/notifications');

  // const handleNavigateExercicios = () => navigate(routes.exercicio);

  // const handleNavigatePerfil = () => navigate(routes.perfilPessoal);

  const handleNavigatePlanos = () => navigate(routes.plano);

  // const handleNavigateTutorial = () => navigate(routes.tutorial);

  const handleNavigateConexoes = () => navigate('/users');

  // const handleNavigateMontarTreinos = () => navigate(routes.montagemTreino);

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const handlePlan = (plan) => {
    switch(plan){
      case 'free':
        return 'Grátis';
      case 'standard':
        return 'Padrão';
      case 'premium':
        return 'Premium';
      default:
        return '';
    }
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, backgroundColor: '#0E100F', color: '#fff' }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ height: '40px', backgroundColor: '#0E100F' }}
          ></ListSubheader>
        }
      >
        <ListItem
          disablePadding
          onClick={handleNavigateConexoes}
          sx={{ mt: 1, mb: 1, cursor: 'pointer' }}
        >
          <ListItemIcon>
            <GroupIcon style={{ padding: 12, color: '#ff66c4' }} />
            {/* <img
              src={GroupIcon}
              width="40%"
              alt="Icone Treino"
              style={{ padding: 8 }}
            /> */}
          </ListItemIcon>
          <ListItemText
            primary={
              'Usuários'
            }
          />
        </ListItem>{' '}
        <ListItem
          disablePadding
          onClick={handleNavigateTreinos}
          sx={{ mt: 1, mb: 1, cursor: 'pointer' }}
        >
          <ListItemIcon>
            <StorageOutlinedIcon style={{ padding: 12, color: '#ff66c4' }} />
            {/* <img
              src={IconePerfil}
              width="40%"
              alt="Icone Treino"
              style={{ padding: 8 }}
            /> */}
          </ListItemIcon>
          <ListItemText primary={'Feedbacks'} />
        </ListItem>
        <ListItem
          disablePadding
          onClick={handleNavigateNotifications}
          sx={{ mt: 1, mb: 1, cursor: 'pointer' }}
        >
          <ListItemIcon>
            <StorageOutlinedIcon style={{ padding: 12, color: '#ff66c4' }} />
            {/* <img
              src={IconePerfil}
              width="40%"
              alt="Icone Treino"
              style={{ padding: 8 }}
            /> */}
          </ListItemIcon>
          <ListItemText primary={'Notificacoes'} />
        </ListItem>
        <ListItem
          disablePadding
          onClick={handleModalVisible}
          sx={{ mt: 1, mb: 1, cursor: 'pointer' }}
        >
          <ListItemIcon>
            <LogoutOutlinedIcon style={{ padding: 12, color: '#727272' }} />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
      <List
        style={{
          position: 'fixed',
          bottom: 0,
          textAlign: 'center',
          paddingBottom: 10,
          backgroundColor: '#0E100F'
        }}
      >
        <ListItem sx={{ paddingLeft: 3 }}>
          <img src={logoMenu} alt="Logo" style={{ padding: 8 }} width={150} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <LogoutModal visible={visible} setVisible={setVisible} />
      {['left'].map((anchor) => (
        <Fragment key={anchor}>
          <AppBar position="fixed" style={{ backgroundColor: '#0E100F' }}>
            <Toolbar>
              <IconButton
                size="medium"
                edge="start"
                color="success"
                onClick={toggleDrawer(anchor, true)}
                aria-label="menu"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
             
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              ></Typography>
              {
                role === 'personal' && Config.mostraPlanos ?
                <>
                  <Chip variant='outlined' color='success' label={'Plano: '+handlePlan(plan)} />
                    {
                      plan !== 'premium' &&
                      <Button
                        color="success"
                        sx={{
                          fontWeight: 'bold',
                          ml: 1,
                          ':hover': { background: '#003F06' },
                          // height: 55,
                        }}
                        // fullWidth
                        variant="contained"
                        onClick={handleNavigatePlanos}
                      >
                        Fazer upgrade
                      </Button>
                    }
                </>
                :
                <></>
              }
              
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="success"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  PaperProps={{
                    sx: {
                      backgroundColor: '#0E100F'
                    }
                  }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{ width: '30rem' }}
                >
                  <ListItemLink href={'/perfil'}>
                    <ListItemText primary="Perfil" style={{ color: '#fff' }} />
                  </ListItemLink>
                  <MenuItem sx={{ width: '7rem', color: '#fff' }} onClick={handleModalVisible}>
                    Sair
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            PaperProps={{
              sx: { backgroundColor: '#0E100F' }
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}
