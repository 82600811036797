import React, { useState } from 'react'
const DeleteAccount = () => {
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
  return (
    <div className='color'>
        <h1>
            Informe seu email e senha para solicitar o encerramento da conta
        </h1>
        <label>Email: </label><input type="text" value={email} onChange={(t) => setEmail(t.target.value)} /><br></br><br></br><br></br>
        <label>Senha: </label><input type="password" value={senha} onChange={(t) => setSenha(t.target.value)}  />
        <br></br><br></br><br></br>
        <input type="submit" value="enviar" onClick={() =>
            {
                if(!email || !senha){
                    alert('Preencha email e senha!')
                    return;
                }
                alert('Você recebera um email informando que sua conta foi desativada com sucesso')
                setEmail('')
                setSenha('')
            } } />
      </div>
  );
};

export default DeleteAccount;