import React from 'react';
import { Modal, Button, Box, Typography, Grid } from '@mui/material';

const ModalDeletar = ({ modalOpen, setModalOpen, onSuccess }) => {
  return (
    <Modal
      fullWidth
      open={modalOpen}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          outline: 'none',
          p: 2,
          width: [240, 358, 442, 595],
          height: [170, 140],
          backgroundColor: '#1C1F1E',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Typography variant="h5" sx={{ mb: 4, color: '#F20101' }}>
          Deletar conexão?
        </Typography>
        <Typography variant="p" sx={{ color: '#bababa', mb: 4 }}>
          Deseja deletar esta conexão?
        </Typography>
        <Grid
          sx={{
            alignSelf: 'end',
          }}
        >
          <Button
            variant="outlined"
            onClick={setModalOpen}
            sx={{
              alignSelf: 'end',
              border: 'none',
              fontWeight: 'bold',
              width: 120,
              color: '#bababa',
            }}
          >
            VOLTAR
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onSuccess();
            }}
            sx={{
              border: 'none',
              fontWeight: 'bold',
              width: 120,
              color: '#F20101',
            }}
          >
            CONFIRMAR
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalDeletar;
