import React from 'react'
const PoliticaPrivacidade = () => {
  return (
    <div className='color'>
      <p><strong>Política de Privacidade - Agenda da Beleza Profissionais</strong></p>

<p><b>POLÍTICA DE PRIVACIDADE</b></p>

<p>O aplicativo Agenda da Beleza Profissionais, conforme foi construído, recolhe informações sobre os seus usuários com a finalidade de conectar os clientes aos prestadores de serviços, de forma mais rápida e dinâmica. Com isso, o intuito deste documento, é demonstrar a absoluta transparência do aplicativo, referente a Política de Privacidade.</p>

<p>Esta Política de Privacidade descreve o funcionamento da coleta, uso e armazenamento, tratamento e proteção dos dados dos usuários que utilizam o aplicativo.</p>

<p>O aplicativo, através da explicitação de sua Política de Privacidade, quer demonstrar que a privacidade da informação dos seus usuários e/ou visitantes é muito importante, por isso, toma precauções e cautelas para resguardá-las, tudo de acordo com os princípio e regras estabelecidos pelo Marco Civil da Internet Lei nº (12.965/14) e a Lei Geral de Proteção de Dados (13.709/18).</p>

<p><b>1. Quais dados pessoais são coletados? Como são coletados? Para qual finalidade?</b></p>

<p>Para atender aos objetivos dos usuários, o aplicativo armazena informações de caráter pessoal, incluindo nome, endereço, telefones, e-mails, possibilitando assim que os usuários clientes e os usuários profissionais interajam diretamente entre si.</p>

<p>Os dados são coletados diretamente do usuário cliente ao aplicativo, quando o mesmo tem o intuito de agendar algum serviço com um profissional da beleza e ao se cadastrar no aplicativo.</p>

<p>As informações pessoais e de dados armazenados têm as seguintes finalidades:</p>
<ul>

<p><b>a.</b> Auxiliar o usuário cliente e o usuário profissional a entrar em contato direto entre si nos processos relativos ao agendamento de serviços e gerenciamento dos horários de atendimento disponibilizados no aplicativo. A informação assim conhecida pelo usuário cliente ou usuário profissional só poderá ser utilizada para efeitos de concluir a operação originada pelo aplicativo.</p>

<p><b>b.</b> Desenvolver estudos e relatórios sobre os interesses, comportamentos, perfil e demografia dos usuários, sem utilizar dados pessoais ou quaisquer outras informações que permitam a individualização de um usuário cliente, resguardando sempre a sua identidade.</p>

<p><b>c.</b> Compreender as necessidades e interesses dos usuários, no intuito de oferecer melhores serviços e prover-lhes informações direcionadas.</p>

<p><b>d.</b> Os dados também ajudam na melhoria do serviço e suporte do aplicativo, assim prestando o suporte direcionado ao cliente.</p>
</ul>
<p><b>2. Por quanto tempo seus dados pessoais serão armazenados.</b></p>

<p>Os dados pessoais que coletamos serão armazenados e utilizados pelo período estritamente necessário, atendendo às finalidades e às justificativas legais para o devido tratamento dos dados. Entretanto, se houver alguma justificativa legal ou regulatória que imponha a manutenção das informações por período superior ao estipulado, os dados poderão continuar armazenados ainda que a finalidade para a qual foram coletados ou tenham sido tratados tenha se exaurido.</p>

<p>Uma vez finalizado o tratamento, observadas as disposições desta seção, os dados são apagados ou anonimizados.</p>

<p><b>3. Confidencialidade das informações</b></p>

<p>O aplicativo não concederá informações pessoais dos usuários cadastros exceto nas formas estabelecidas nesta política, para fins de melhorias no aplicativo, bem como transferência de relatórios, visando o aperfeiçoamento do mercado de produtos e serviços de beleza.</p>

<p>O aplicativo adotará as medidas a fim de proteger a privacidade de todas as informações cadastradas, excepcionalmente, em virtude de ordens judiciais ou de disposições legais.</p>

<p><b>4. Exclusões de dados Pessoais</b></p>

<p>Para solicitar a exclusão das suas informações cadastradas no aplicativo, basta você realizar o pedido de exclusão da sua conta, enviando um e-mail para: anotacontas@gmail.com </p>

<p>Para exclusão através do aplicativo celular, clique em: Perfil, Excluir conta.</p>

<p><b>5. Alterações nesta política.</b></p>

<p>Nos reservamos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso aplicativo, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</p>

<p><b>6. Como entrar em contato conosco.</b></p>

<p>Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato por algum dos canais mencionados abaixo:</p>

<p><i>anotacontas@gmail.com</i></p>
<p><i>Whats Suporte: 051991414737</i></p>
<p><i>Instagram: https://www.instagram.com/agendadabelezaapp/</i></p>

<br>
</br>
<br>
</br>
<br>
</br>
<br>
</br>
<blockquote><i>Política de Privacidade: última versão em 30 de setembro de 2024</i></blockquote>
      </div>
  );
};

export default PoliticaPrivacidade;