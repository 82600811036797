import React from 'react'
const TermoUso = () => {
  return (
    <div className='color'>
      <p><strong>TERMO DE USO PARA A UTILIZA&Ccedil;&Atilde;O DO APLICATIVO- AGENDA DA BELEZA PROFISSIONAIS</strong></p>
      <br></br>
<p>Neste Termo de Uso, est&atilde;o as principais regras que voc&ecirc; dever&aacute; aceitar e cumprir ao acessar e usar a Agenda da Beleza Profissionais, dispon&iacute;vel atrav&eacute;s do aplicativo para dispositivos m&oacute;veis.</p>
<p><strong>Aceita&ccedil;&atilde;o do Termo de Uso</strong></p>
<p>Para utilizar o nosso Aplicativo, voc&ecirc; dever&aacute; aceitar e cumprir todas as regras previstas neste Termo de Uso. E, em algumas situa&ccedil;&otilde;es, o uso depender&aacute; da cria&ccedil;&atilde;o de cadastro.</p>
<p>Ent&atilde;o, se voc&ecirc; n&atilde;o concordar com alguma condi&ccedil;&atilde;o, orientamos que n&atilde;o use o aplicativo.</p>
<ol>
<strong>1  Informa&ccedil;&otilde;es gerais sobre o aplicativo e seu funcionamento</strong>
</ol>
<p>1.1. O Aplicativo &eacute; administrado por HERTECH INNOVATIONS LTDA, inscrita no CNPJ n&ordm; 55.688.591/0001-23, sediada na Rua Brasil, n&ordm; 384 &ndash; Bloco 5 Casa 52, Bairro Centro, Canoas/RS, CEP 92310-150.</p>
<p>1.2. As finalidades deste Aplicativo s&atilde;o: Permitir agendamento de servi&ccedil;os de beleza e bem-estar em estabelecimentos/profissionais cadastrados no aplicativo. O aplicativo n&atilde;o &eacute; respons&aacute;vel pela presta&ccedil;&atilde;o dos servi&ccedil;os.</p>
<p>1.3. Sempre buscamos melhorar as funcionalidades do aplicativo. Com isso, o Termo de Uso pode ser atualizado a qualquer tempo.</p>
<ol>
<strong>  2 Licen&ccedil;a</strong>
</ol>
<p>2.1. Concedemos a voc&ecirc; licen&ccedil;a limitada, n&atilde;o exclusiva, n&atilde;o pass&iacute;vel de sub licen&ccedil;a, revog&aacute;vel e n&atilde;o transfer&iacute;vel para acesso e uso do aplicativo em um dispositivo. A licen&ccedil;a &eacute; exclusivamente para uso pessoal, e para a finalidade do aplicativo, conforme descrito neste Termo.</p>
<p>2.2 Novas assinaturas a partir da data de criação deste termo, possuem 10 dias de degustação grátis de todos os recursos do aplicativo.</p>
<p>2.3 Ao realizar a assinatura do aplicativo, não esqueça que o <b>e-mail usado na plataforma</b> stripe, deve ser o mesmo cadastrado no aplicativo para que a <b>ativação</b> seja realizada <b>automaticamente de forma rápida</b>.</p>
<ol>
<strong> 3 Seus deveres</strong>
</ol>
<p>3.1 Ao acessar o aplicativo, voc&ecirc; declara e garante o seu compromisso em agir de forma &eacute;tica e l&iacute;cita e em respeitar as condi&ccedil;&otilde;es de uso, incluindo:</p>
<ol>
a) N&atilde;o acessar o aplicativo por meios fraudulentos; 
<br></br>
b) N&atilde;o utilizar o aplicativo para a publica&ccedil;&atilde;o, cria&ccedil;&atilde;o, armazenamento e/ou divulga&ccedil;&atilde;o de:
</ol>
<ol>
<li>Conte&uacute;dos abusivos com car&aacute;ter difamat&oacute;rio, obsceno ou ofensivo. Conte&uacute;dos que possam incentivar atitudes discriminat&oacute;rias, viol&ecirc;ncia ou a pr&aacute;tica de crimes. Conte&uacute;dos que atentem contra aos bons costumes e &agrave; moral. Ou ainda, conte&uacute;dos que contrariem a ordem p&uacute;blica;</li>
<li>Informa&ccedil;&otilde;es difamat&oacute;rias e caluniosas. Informa&ccedil;&otilde;es que sejam contr&aacute;rias &agrave; honra, &agrave; intimidade pessoal e familiar. Ou ainda, contr&aacute;rias &agrave; imagem das pessoas. Inclusive, de pessoas jur&iacute;dicas, entidades e organiza&ccedil;&otilde;es. E tamb&eacute;m da pr&oacute;pria Agenda da Beleza Profissionais.</li>
<li>Apologia a qualquer pr&aacute;tica de crimes ou atos il&iacute;citos. Tais como, mas n&atilde;o se limitando a: pornografia, pedofilia e outras modalidades de satisfa&ccedil;&atilde;o sexual; racismo ou discrimina&ccedil;&atilde;o de qualquer natureza; <em>bullying</em>, <em>stalking</em> ou qualquer outra esp&eacute;cie de constrangimento ilegal; viola&ccedil;&atilde;o ao direito autoral ou direito de imagem; utiliza&ccedil;&atilde;o de entorpecentes, estupro, homic&iacute;dio ou estelionato;</li>
<li>Propaganda pol&iacute;tica ou publicidade de terceiros;</li>
<li>Qualquer tipo de promo&ccedil;&atilde;o que envolva sorte, concurso, vale brinde ou sorteio. Ou ainda, em que haja a distribui&ccedil;&atilde;o gratuita de pr&ecirc;mios a t&iacute;tulo de propaganda para consumidores finais.</li>
</ol>
<ol>
c) Utilizar os recursos do aplicativo para a finalidade que foi constitu&iacute;da;
<br></br>
d) Fornecer seus dados e informa&ccedil;&otilde;es com o compromisso de veracidade e autenticidade. E mant&ecirc;-los sempre atualizados.
</ol>
<p>3.2 Caso envie conte&uacute;dos ao aplicativo (upload), voc&ecirc; declara deter os direitos ou possuir as licen&ccedil;as de exibi&ccedil;&atilde;o. Assim, responsabilizando-se exclusivamente e isentando a Agenda da Beleza- Profissionais em caso de qualquer questionamento. Incluindo, mas n&atilde;o se limitando a:</p>
<ol>
<li>Direito ou licen&ccedil;a sobre imagem, nome, conte&uacute;dos, textos, &aacute;udios ou v&iacute;deos. Inclusive aqueles que tenham servido de inspira&ccedil;&atilde;o;</li>
<li>Direito de patentes ou licen&ccedil;a para uso de informa&ccedil;&otilde;es de terceiros;</li>
<li>Quaisquer outros direitos, inclusive de terceiros, que possam impedir ou violar o compartilhamento. Seja de forma institucional ou comercial.</li>
</ol>
<p>3.3. Voc&ecirc; &eacute; a &uacute;nica pessoa respons&aacute;vel pela integridade e veracidade das informa&ccedil;&otilde;es inseridas em nosso Aplicativo. Voc&ecirc; responde civil e criminalmente pelos efeitos advindos de uso irregular de informa&ccedil;&otilde;es de terceiros ou informa&ccedil;&otilde;es falsas.</p>
<p>3.4. Voc&ecirc; &eacute; a &uacute;nica pessoa respons&aacute;vel por suas informa&ccedil;&otilde;es de login e senha. Sendo que, voc&ecirc; dever&aacute; cuidar do sigilo e seguran&ccedil;a desses dados. Estas informa&ccedil;&otilde;es determinam a sua identidade digital. Portanto, conferem a voc&ecirc; a autoria de todos os atos praticados em seu nome. Inclusive atos praticados por terceiro, que tenha conhecimento dos seus dados.</p>
<p>3.4.1. Os dados de login e senha s&atilde;o personal&iacute;ssimos. Em hip&oacute;tese alguma, devem ser compartilhados, cedidos ou vendidos. Ou ainda, qualquer outra modalidade de transfer&ecirc;ncia de direitos de uso para quem quer que seja.</p>
<p>3.4.2. Caso ocorra algum incidente com os dados de login e senha - furto, extravio, perda ou suspeita de quebra de sigilo -, voc&ecirc; dever&aacute; alterar sua senha e comunicar de imediato a Agenda da Beleza- Profissionais atrav&eacute;s de nossos canais de atendimento. O intuito &eacute; evitar danos que podem ser de dif&iacute;cil repara&ccedil;&atilde;o.</p>
<p>3.5. Caso hajam raz&otilde;es para crermos que voc&ecirc; est&aacute; descumprindo, facilitando o descumprimento deste Termo de Uso ou, ainda, suspeita de inexatid&atilde;o de informa&ccedil;&otilde;es e/ou pr&aacute;tica criminosa, de maneira que tal pr&aacute;tica possa nos imputar qualquer infra&ccedil;&atilde;o de qualquer lei aplic&aacute;vel ou que o aplicativo esteja sendo utilizada, sob nossa avalia&ccedil;&atilde;o, para quaisquer fins ilegais, il&iacute;citos ou contr&aacute;rios &agrave; moralidade, a Agenda da Beleza- Profissionais poder&aacute;, a seu exclusivo crit&eacute;rio e conveni&ecirc;ncia, excluir ou suspender sua conta de acesso ao aplicativo, bem como retirar o conte&uacute;do eventualmente inserido por voc&ecirc; no aplicativo.</p>
<p>3.6. O descumprimento de qualquer cl&aacute;usula deste Termo de Uso poder&aacute; gerar responsabiliza&ccedil;&atilde;o c&iacute;vel e penal, quando aplic&aacute;vel.</p>
<ol>
<strong>4 Veda&ccedil;&otilde;es</strong>
</ol>
<p>4.1. Al&eacute;m das demais proibi&ccedil;&otilde;es descritas neste Termo de Uso, &eacute; expressamente proibido:</p>
<ol>
<li>Praticar quaisquer atos de explorar e usufruir de vulnerabilidade ou fragilidade de programa&ccedil;&atilde;o do aplicativo, ou que atue de modo similar, e possam causar preju&iacute;zo ao aplicativo Agenda da Beleza Profissionais;</li>
<li>Praticar quaisquer atos que possam causar preju&iacute;zo ao aplicativo Agenda da Beleza Profissionais por meio de c&oacute;digos maliciosos, ou por quaisquer outros dispositivos que venham a ser criados.</li>
</ol>
<p>4.2. Em qualquer uma das hip&oacute;teses acima, voc&ecirc; est&aacute; sujeito &agrave; legisla&ccedil;&atilde;o civil e penal brasileira. Assim como a reparar os danos que causar.</p>
<ol>
<strong> 5 Responsabilidades</strong>
</ol>
<p>5.1. O aplicativo Agenda da Beleza- Profissionais, apenas realiza a intermedia&ccedil;&atilde;o entre voc&ecirc; e os estabelecimentos/profissionais cadastrados. O aplicativo Agenda da Beleza- Profissionais n&atilde;o &eacute; fornecedor de nenhum dos servi&ccedil;os divulgados no aplicativo. Assim, os estabelecimentos/profissionais cadastrados s&atilde;o os &uacute;nicos respons&aacute;veis pela presta&ccedil;&atilde;o e qualidade dos servi&ccedil;os.</p>
<p>5.2. Buscamos adotar os mecanismos e tecnologias dispon&iacute;veis para manter o aplicativo seguro. Por&eacute;m, sendo ambiente virtual, estamos sujeitos a eventos maliciosos de terceiros.</p>
<p>5.3. O aplicativo Agenda da Beleza- Profissionais se esfor&ccedil;a para oferecer disponibilidade cont&iacute;nua e permanente do aplicativo, ressalvados os momentos em que houver a necessidade de realiza&ccedil;&atilde;o de manuten&ccedil;&otilde;es t&eacute;cnicas. Contudo, n&atilde;o h&aacute; garantia de disponibilidade ininterrupta. H&aacute; suscetibilidade a eventos extraordin&aacute;rios: desastres naturais, falhas ou colapsos nos sistemas centrais de comunica&ccedil;&atilde;o e acesso &agrave; internet ou fatos de terceiro. Sendo que estes fogem da esfera de vigil&acirc;ncia e responsabilidade do aplicativo Agenda da Beleza- Profissionais.</p>
<p>5.4. Neste sentido, o aplicativo Agenda da Beleza- Profissionais n&atilde;o se responsabiliza por eventual dano, preju&iacute;zo ou perda no seu equipamento causado por:</p>
<ol>
<li>Suspens&atilde;o ou interrup&ccedil;&atilde;o da opera&ccedil;&atilde;o do aplicativo;</li>
<li>Problemas com a transmiss&atilde;o de dados;</li>
<li>Problemas, <em>bugs</em> (falhas de c&oacute;digo) ou funcionamentos indevidos que ocorrerem nos seus dispositivos ou equipamentos;</li>
<li>Quaisquer fatos fora do controle do aplicativo Agenda da Beleza- Profissionais;</li>
<li>Eventuais procedimentos de manuten&ccedil;&atilde;o.</li>
</ol>
<p>5.5. O aplicativo Agenda da Beleza- Profissionais responde apenas por danos materiais e diretos de sua responsabilidade. Exclu&iacute;dos expressamente os lucros cessantes, danos indiretos ou outros danos n&atilde;o materiais.</p>
<p>5.6. Sem preju&iacute;zo de outras condi&ccedil;&otilde;es, n&atilde;o nos responsabilizamos ou oferecemos garantias quanto &agrave; utiliza&ccedil;&atilde;o do aplicativo. Igualmente sobre os seus conte&uacute;dos.</p>
<ol>
<strong>6 Cadastro</strong>
</ol>
<p>6.1. As funcionalidades de agendamento de servi&ccedil;os est&atilde;o dispon&iacute;veis apenas para pessoas cadastradas. Para realizar o cadastro e us&aacute;-las, voc&ecirc; dever&aacute; fornecer alguns dados pessoais. Al&eacute;m disso, dever&aacute; criar uma senha forte para sua identifica&ccedil;&atilde;o e autentica&ccedil;&atilde;o.</p>
<p>6.1.1. O acesso &agrave; &aacute;rea restrita &agrave;s pessoas cadastradas &eacute; realizado mediante identifica&ccedil;&atilde;o de login e senha.</p>
<p>6.2. Com o cadastro no aplicativo, voc&ecirc; poder&aacute;:</p>
<p></p>
<ul>
<li><strong>Visualizar os servi&ccedil;os disponibilizados pelos estabelecimentos/profissionais cadastrados cadastrados;</strong></li>
</ul>
<ul>
<li>Obter informa&ccedil;&otilde;es sobre os estabelecimentos/profissionais cadastrados;</li>
</ul>
<ul>
<li>Agendar servi&ccedil;os;</li>
</ul>
<ul>
<li>Cancelar servi&ccedil;os agendados;</li>
</ul>
<ul>
<li>Avaliar os servi&ccedil;os;</li>
</ul>
<ul>
<li>Consultar hist&oacute;rico de agendamentos;</li>
</ul>
<p></p>
<p>6.4. Para realizar o cadastro, voc&ecirc; dever&aacute; fornecer informa&ccedil;&otilde;es verdadeiras e atualizadas. Em caso de informa&ccedil;&otilde;es incorretas, inver&iacute;dicas ou n&atilde;o confirmadas, nos reservamos no direito de n&atilde;o concluir o cadastramento ou de bloquear o cadastro j&aacute; existente, at&eacute; que a situa&ccedil;&atilde;o seja regularizada.</p>
<p>6.5. Algumas informa&ccedil;&otilde;es de cadastro ser&atilde;o obrigat&oacute;rias, a depender da funcionalidade. Por exemplo: para localiza&ccedil;&atilde;o de servi&ccedil;os pr&oacute;ximos &agrave; sua resid&ecirc;ncia, voc&ecirc; precisar&aacute; informar endere&ccedil;o.</p>
<p>6.6. Sempre que precisar, voc&ecirc; poder&aacute; alterar sua senha e/ou informa&ccedil;&otilde;es no seu perfil.</p>
<p>6.7. Usaremos o e-mail e telefone informado no cadastro como principal meio de comunica&ccedil;&atilde;o. Seja para confirmar seu cadastro ou at&eacute; no processo de altera&ccedil;&atilde;o de senha. Com isso, &eacute; sua responsabilidade configurar sistemas anti-spam para garantir os recebimentos.</p>
<ol>
<strong> 7 Agendamento</strong>
</ol>
<p>7.1. Os servi&ccedil;os, hor&aacute;rios e pre&ccedil;os dispon&iacute;veis no aplicativo s&atilde;o inseridos pelos pr&oacute;prios estabelecimentos/profissionais cadastrados, e podem ser alterados a qualquer momento pelos mesmos. O Aplicativo n&atilde;o verifica a disponibilidade e calend&aacute;rios dos estabelecimentos/profissionais, n&atilde;o sendo respons&aacute;vel por qualquer problema neste sentido.</p>
<p>7.2. Para agendamentos, voc&ecirc; precisa fazer o cadastro e fornecer as informa&ccedil;&otilde;es solicitadas.</p>
<p>7.3. A solicita&ccedil;&atilde;o de agendamento ser&aacute; conclu&iacute;da e processada pelo sistema ap&oacute;s a (i) escolha do estabelecimento, (ii) escolha do servi&ccedil;o, (iii) escolha do dia/hor&aacute;rio e profissional, (iv) confirma&ccedil;&atilde;o da solicita&ccedil;&atilde;o.</p>
<p>7.4. O aplicativo Agenda da Beleza- Profissionais n&atilde;o intermedia pagamentos. Voc&ecirc; dever&aacute; pagar pelos servi&ccedil;os diretamente aos estabelecimentos/profissionais cadastrados.</p>
<p>7.5. As pol&iacute;ticas de cancelamento s&atilde;o definidas pelos pr&oacute;prios estabelecimentos/profissionais cadastrados.</p>
<p>7.6. O aplicativo disponibiliza as informa&ccedil;&otilde;es e dados de contato dos estabelecimentos/profissionais cadastrados. Qualquer questionamento sobre os servi&ccedil;os deve ser feito diretamente aos estabelecimentos/profissionais.</p>
<ol>
<strong> 8 Disposi&ccedil;&otilde;es gerais</strong>
</ol>
<p>8.1. Este aplicativo tem como base o hor&aacute;rio oficial de Bras&iacute;lia.</p>
<p>8.2. A toler&acirc;ncia do eventual descumprimento de quaisquer das cl&aacute;usulas e condi&ccedil;&otilde;es deste Termo de Uso n&atilde;o constituir&aacute; nova&ccedil;&atilde;o das obriga&ccedil;&otilde;es aqui estipuladas. Tampouco, impedir&aacute; ou inibir&aacute; a exigibilidade das mesmas a qualquer tempo.</p>
<p>8.3. O presente Termo de Uso &eacute; regido pela legisla&ccedil;&atilde;o da Rep&uacute;blica Federativa do Brasil. Sendo eleito o Foro da Comarca de Canoas, no Estado Rio Grande do Sul, para resolver quaisquer controv&eacute;rsias ou quest&otilde;es decorrentes deste Termo de Uso ora celebrado.</p>
<p></p>

<p><strong>9. Cancelamentos de Reembolso do Aplicativo</strong><br /></p>

<p>O cancelamento de uma contratação de assinatura do aplicativo poderá ocorrer dentro do prazo de até 10 dias corridos após a realização da degustação grátis oferecida. Após o período de 10 dias não haverá reembolso e os serviços contratados na assinatura ficarão disponíveis até o fim do período contratado.
O cancelamento, em qualquer situação, pode ser feito diretamente pelo app, na opção excluir conta, ou entrando em contato conosco, pelos meios de comunicação.
</p>

<blockquote><p><i>Termo de Uso: &uacute;ltima vers&atilde;o em 28 de outubro de 2024</i></p></blockquote>
<p><br/><br/></p>
     </div>
  );
};

export default TermoUso;