import axios from 'axios';
import { toast } from 'react-toastify';
import handleError from '../util/handleError';
export const API_URL = 'https://hertech-api.azurewebsites.net';

const axiosInstance = axios.create({
  baseURL: `${API_URL}`,
  timeout: 300000,
});

axiosInstance.interceptors.request.use(
  (request) => {
    // if (process.env.NODE_ENV === "development") {
    //   console.log("REQUEST", { url: request.url, data: request.data });
    // }

    if (!request.headers) return;

    // if (accessToken) {
    //   request.headers.Authorization = `Bearer ${accessToken}`;
    // }

    return request;
  },
  (err) => {
    console.log('REQUEST ERROR');
    handleError(err);

    throw err;
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log(response.config.url, {
      data: response.data,
      status: response.status,
    });

    return response;
  },
  (err) => {
    toast.error(err.response.data.error);

    console.log('RESPONSE ERROR');
    throw err;
  }
);

export default axiosInstance;
