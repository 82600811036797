import axiosInstance from './axios-instance';

const FeedbackApi = {
  getAll: async () => {
    const { data } = await axiosInstance.get('/api/feedback');

    return data;
  }
};

export default FeedbackApi;
