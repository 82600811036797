import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import UsersApi from '../../services/users'
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

  const daysWeekly = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  console.log(daysWeekly)

const ConfirmarAgendamento = () => {
    const [agendas, setAgendas] = useState([]);
    const [lancamentos, setLancamentos] = useState([]);
    const [timezone, setTimezone] = useState('America/Sao_Paulo')
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)
    const [id, setId] = useState();
    const [email, setEmail] = useState('');
    const [selectedAgenda, setSelectedAgenda] = useState()
    const [cancelado, setCancelado] = useState(false)
    const [pushToken, setPushToken] = useState('')
    const [clientes, setClientes] = useState()
    
    const loadData = async () => {
        try{
            const mail = searchParams.get('key');
            const _id = searchParams.get('id')
            setEmail(mail)
            
            const response = await UsersApi.getByEmail(mail)
            setLoading(false)
            setAgendas(JSON.parse(response[0].agendas))
            setLancamentos(JSON.parse(response[0].lancamentos))
            setClientes(JSON.parse(response[0].clientes))
            setPushToken(response[0].pushToken)
            setId(_id)
            setTimezone(response[0].timezone)
            console.log(agendas, timezone, selectedAgenda)
        }catch(e){
            console.log(e)
        }
       
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if(id){
            const agenda = agendas.find(x => x.id === id)
            if(agenda){
                setSelectedAgenda(agenda)
            }
            
        }
    },[id, agendas])

    const handleCancel = async () => {
        try{
            const cancelar = window.confirm('Deseja realmente cancelar?')
            setLoading(true)
            if(cancelar){
                console.log('AQUI')
                const filteredAgendas = agendas.filter(x => x.id !== id)
                const filteredLancamentos = lancamentos.filter(x => x.calendar !== selectedAgenda?.releaseData?.calendar)
                await UsersApi.postUsuario({
                    agendas: JSON.stringify(filteredAgendas),
                    lancamentos: JSON.stringify(filteredLancamentos),
                    email: email
                })
                window.alert('Agendamento cancelado com sucesso!')

                const messages = []
                messages.push({
                    to: pushToken,
                    title: 'Agendamento cancelado pelo cliente!',
                    body: `Cliente: ${clientes.find(x => x.id === selectedAgenda?.clientId)?.name} - ${new Date(selectedAgenda?.hour).toLocaleDateString('pt-BR', timezone)} - ${new Date(selectedAgenda?.hour).toLocaleTimeString('pt-BR', timezone)} \n ${selectedAgenda?.releaseData?.category}`
                })
                await UsersApi.sendNotifications(messages)
                setCancelado(true)
            }
        }catch(e){
            console.log(e)
        }finally{
            setLoading(false)
        }
    }
    
    return (
        <div style={{ padding: 20 }}>
            {
                loading ?
                <CircularProgress />
                :
                <>
                {
                    !cancelado ?
                    <div>
                        <h3 style={{ color: '#fff' }}>Deseja cancelar o agendamento?</h3>
                        {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
                            {
                                selectedAgenda ?
                                <>
                                    <h4 style={{ color: '#fff' }}>Serviço: {selectedAgenda?.releaseData?.category}</h4>
                                    {/* <br></br> */}
                                    <h4 style={{ color: '#fff' }}>Data: {new Date(selectedAgenda?.hour).toLocaleDateString('PT-br', { timeZone: timezone } )} - {new Date(selectedAgenda?.hour).toLocaleTimeString('PT-br', { timeZone: timezone } ).substring(0, 5)}</h4>
                                </>
                                :
                                <></>
                            }
                        {/* </div> */}
                        <Button disabled={!selectedAgenda} sx={{ color: '#fff', background: '#ff66c4', borderColor: '#ff66c4', fontWeight: 'bold', mr: 1, ':hover': { color: '#fff', background: '#ff66c4' } }} color="success" style={{ marginTop: 10 }} fullWidth onClick={() => handleCancel()} variant="contained">Cancelar agendamento</Button>  
                        </div>
                    :
                    <h3 style={{ color: '#fff' }}>Agendamento cancelado com sucesso</h3>
                }
                   
                </>
            }
                
        </ div>
    )
}

export default ConfirmarAgendamento