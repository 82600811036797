import axiosInstance from './axios-instance';

const VinculoApi = {
  removeLink: async (idPersonal, idAluno) => {
    const { data } = await axiosInstance.post(
      '/api/desvincula/personal/aluno',
      {
        idPersonal: idPersonal,
        idAluno: idAluno,
      }
    );

    return data;
  },

  link: async (id, email) => {
    const { data } = await axiosInstance.post('/api/vinculo/aluno/personal', {
      emailAluno: email,
      idPersonal: id,
    });

    return data;
  },
};

export default VinculoApi;
