import axiosInstance from './axios-instance';

const UsersApi = {
  getByEmail: async (email) => {
    const { data } =  await axiosInstance.get('/api/usuario?email='+email);

    return data;
  },
  getPremiumData: async (email) => {
    const { data } =  await axiosInstance.get('/api/usuario/getPremiumData?email='+email);

    return data;
  },
  postUsuario: async (sync) => {
    const { data } =  await axiosInstance.post('/api/usuario', sync);

    return data;
  },
  getAll: async () => {
    const { data } = await axiosInstance.get('/api/usuarios');

    return data;
  },
  getAllNotifications: async () => {
    const { data } = await axiosInstance.get('/api/usuario/notifications');

    return data;
  },
  deleteNotifications: async (notifications) => {
    const { data } = await axiosInstance.post('/api/usuario/notification/delete', { notifications });

    return data;
  },
  toglePremium: async (email, premium) => {
    const { data } = await axiosInstance.post('/api/usuario/premium', { email, premium })

    return data;
  },
  sendNotifications: async (notifications) => {
    const { data } = await axiosInstance.post('/api/usuario/sendnotification', { notifications })

    return data;
  },
  changeData: async (usuario) => {
    const { data } = await axiosInstance.post('/api/usuario', { ...usuario })

    return data;
  }
};

export default UsersApi;
