import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

export default function AlertDialog({
  whatAlert,
  handleClose,
  isOpen,
  handleRegisterNew,
  loading,
}) {
  const [state, setState] = useState('');
  const [loader, setLoader] = useState(false);

  const validationSchema = yup.object({
    nome: yup.string('insira um nome').required('Nome é obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      nome: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const keyDown = (e) => {
    if (e.key === 'Enter') {
      handleRegisterNew(formik.values.nome);
    }
  };

  const Success = () => {
    return (
      <div style={{ backgroundColor: '#1C1F1E'}} tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClose();
        }
      }}>
        <DialogTitle
          id="alert-dialog-title"
          color="#ff66c4"
          variant="h5"
          sx={{ mb: 3 }}
        >
          Aluno adicionado com sucesso
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            variant="body2"
            sx={{ color: '#727272' }}
          >
            <Typography variant="body2" sx={{ color: '#bababa', mb: 2 }}>
              Este aluno foi adicionado à sua lista de alunos e está pronto para
              receber seus primeiros treinos.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: 'bold', ml: 2 }}
            size="small"
            color="success"
            onClick={handleClose}
          >
            CONTINUAR
          </Button>
        </DialogActions>
      </div>
    );
  };

  const LetsRegister = () => {
    return (
      <div>
        <DialogTitle
          id="alert-dialog-title"
          color={'secondary'}
          variant="h5"
          sx={{ pl: 3 }}
        >
          Vamos criar um cadastro?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            variant="body2"
            sx={{ color: '#bababa' }}
          >
            <Typography variant="body2" sx={{ color: '#bababa', mb: 2 }}>
              Insira abaixo o e-mail do aluno. Caso o aluno não possua cadastro,
              você poderá criar um para ele com o email inserido.
            </Typography>
            <TextField
              size="small"
              type="email"
              id="email"
              name="email"
              label="Insira o email do aluno"
              fullWidth
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            pl: 3,
            pb: 4,
          }}
        >
          <Button
            sx={{ fontWeight: 'bold', color: '#727272' }}
            size="small"
            color="secondary"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            sx={{ fontWeight: 'bold' }}
            size="small"
            color="secondary"
            onClick={() => setState('register')}
          >
            Criar Cadastro
          </Button>
        </DialogActions>
      </div>
    );
  };

  const SuccessRegister = () => {
    return (
      <div>
        <DialogTitle
          id="alert-dialog-title"
          color={'secondary'}
          variant="h5"
          sx={{ mb: 3 }}
        >
          Aluno adicionado com sucesso 2
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            variant="body2"
            sx={{ color: '#727272' }}
          >
            Este aluno foi adicionado à sua lista de alunos e está pronto para
            receber seus primeiros treinos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: 'bold', ml: 2 }}
            size="small"
            color="secondary"
            onClick={handleClose}
          >
            CONTINUAR
          </Button>
        </DialogActions>
      </div>
    );
  };

  const SuccessNewAlunoRegister = () => {
    return (
      <div
        style={{ backgroundColor: '#1C1F1E'}}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClose();
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          color="#ff66c4"
          variant="h5"
          sx={{ mb: 3 }}
        >
          Cadastro realizado com sucesso
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            variant="body2"
            sx={{ color: '#727272' }}
          >
            Este aluno já poderá acessar utilizando a senha abaixo (atenção para
            o M maiúsculo)
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            variant="h5"
            color="#ff66c4"
          >
            123@Mudar
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: 'bold', ml: 2 }}
            size="small"
            color="success"
            onClick={() => {
              handleClose();
            }}
          >
            FINALIZAR
          </Button>
        </DialogActions>
      </div>
    );
  };

  let elements;

  useEffect(() => {
    setState(whatAlert);
  }, [whatAlert]);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  switch (state) {
    case 'succsess':
      elements = <Success />;
      break;
    case 'register':
      elements = <SuccessRegister />;
      break;

    case 'register-new':
      elements = <SuccessNewAlunoRegister />;
      break;
    case 'notfound':
      elements = (
        <div style={{backgroundColor: '#1C1F1E'}}>
          <DialogTitle
            id="alert-dialog-title"
            color="#ff66c4"
            variant="h5"
            sx={{ mb: 1 }}
          >
            Vamos criar um cadastro?
          </DialogTitle>
          {loader == true ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 20,
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  variant="body2"
                  sx={{ color: '#727272' }}
                >
                  Insira abaixo o nome do aluno para criar um cadastro.
                </DialogContentText>
              </DialogContent>

              <Grid container sx={{ p: 3 }}>
                <div className="container-fluid"></div>

                <TextField
                  color="success"
                  InputLabelProps={{ style: { color: '#fff' } }}
                  sx={{ width: '100%', backgroundColor: '#1C1F1E', input: { color: 'white' } }}
                  size={'large'}
                  label="Nome do aluno"
                  variant="outlined"
                  placeholder="Nome do aluno"
                  id="nome"
                  name="nome"
                  value={formik.nome}
                  onKeyDown={keyDown}
                  fullWidth
                  onChange={formik.handleChange}
                />
              </Grid>
            </>
          )}

          <DialogActions>
            <Button
              sx={{ fontWeight: 'bold', color: '#727272' }}
              size="small"
              onClick={handleClose}
              disabled={loader}
            >
              VOLTAR
            </Button>
            <Button
              sx={{ fontWeight: 'bold' }}
              size="small"
              color="success"
              disabled={loader}
              onClick={() => {
                handleRegisterNew(formik.values.nome);
              }}
            >
              CONTINUAR
            </Button>
          </DialogActions>
        </div>
      );
      break;
    case 'letsRegister':
      elements = <LetsRegister />;
      break;
    default:
      elements = <div />;
      break;
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ minWidth: 275, pl: 1, pr: 2 }}
      >
        {elements}
      </Dialog>
    </div>
  );
}

//AlertAddStudent
