import axiosInstance from './axios-instance';

const AlunoApi = {
  getAlunoByPersonal: async (id) => {
    const { data } = await axiosInstance.get(`/api/aluno/personalId?id=${id}`);

    return data;
  },

  getPerfil: async (id) => {
    const { data } = await axiosInstance.get(`/api/aluno/id?id=${id}`);

    return data;
  },

  getAlunoByEmail: async (email) => {
    const { data } = await axiosInstance.get(`/api/aluno/email?email=${email}`);

    return data;
  },

  registerAluno: async (nome, email, password) => {
    const { data } = await axiosInstance.post('/api/aluno', {
      nomeCompleto: nome,
      email: email.toLowerCase(),
      password: password ?? '123@Mudar',
      role: 'aluno',
    });

    return data;
  },

  updateAluno: async (id, newPerfil) => {
    const { data } = await axiosInstance.put(`/api/aluno?_id=${id}`, {
      email: newPerfil.email,
      dataNascimento: newPerfil.dataNascimento,
      telefone: newPerfil.telefone,
      rua: newPerfil.rua,
      numeroResidencia: newPerfil.numeroResidencia,
      complemento: newPerfil.complemento,
      bairro: newPerfil.bairro,
      cidade: newPerfil.cidade,
      uf: newPerfil.uf,
      cep: newPerfil.cep,
      pais: newPerfil.pais,
    });

    return data;
  },
};

export default AlunoApi;
