/* eslint-disable no-unused-vars */
import React, { createContext, ReactNode, useState } from 'react';

export const ProviderContext = createContext({});

const Provider = ({ children }) => {
  const [state, setState] = useState({ user: null, role: null });

  return (
    <ProviderContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export { Provider };
