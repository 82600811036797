import React from 'react';
import { Modal, Button, Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ModalUpgradePlano = ({ modalOpen, setModalOpen }) => {
  const navigate = useNavigate();
  return (
    <Modal
      fullWidth
      open={modalOpen}
      onClose={setModalOpen}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          outline: 'none',
          p: 2,
          width: 500,
          height: 200,
          backgroundColor: '#1C1F1E',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Typography variant="h5" color="primary" sx={{ mb: 4, color: '#ff66c4' }}>
          Limite de alunos excedido
        </Typography>
        <Typography variant="p" sx={{ color: '#bababa', mb: 4 }}>
          Poxa, parece que você chegou ao limite máximo de alunos para o seu plano.
          {'\n'}
          Que tal fazer um upgrade?
        </Typography>
        <Grid
          sx={{
            alignSelf: 'end',
            justifyContent: 'space-between'
          }}
        >
            <Button
                variant="outlined"
                onClick={setModalOpen}
                sx={{
                alignSelf: 'end',
                border: 'none',
                fontWeight: 'bold',
                width: 120,
                color: '#bababa',
                }}
            >
                VOLTAR
            </Button>
            <Button
            variant="contained"
            color="success"
            onClick={() => navigate('/planos')}
            sx={{
                alignSelf: 'end',
                // border: 'none',
                fontWeight: 'bold',
                width: 200,
            }}
            >
            Fazer upgrade
            </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalUpgradePlano;
