import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  TextField,
  Card,
  Button,
  InputAdornment,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../assets/agenda-rosa.png';
import Google from '../../assets/googlePlay.png';
import Apple from '../../assets/appStore.png';
import CloseIcon from '@mui/icons-material/Close';
import { useStore } from '../../hooks';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axiosInstance from '../../services/axios-instance';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import { getAnalytics, logEvent } from 'firebase/analytics';

const Login = () => {
  const store = useStore();
  const navigate = useNavigate();
  
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const analytics = getAnalytics();

  const { state } = useLocation();

  useEffect(() => {
    // eslint-disable-next-line camelcase
    logEvent(analytics, 'page_view', { page_location: '/login', page_title: 'login' })
  }, [])

  useEffect(() => {
    const user = store.getUser();
    if(user.role && user.user){
      if (user.user && user.role === 'aluno') {
        if (screen.width < 640 || screen.height < 480) {
          // versao celular
          navigate('/relacao-treinos');
        } else if (screen.width < 1024 || screen.height < 768) {
          // versão tablet
          navigate('/relacao-treinos');
        } else {
          // versão normal
          setModalOpen(true);
        }
      } else {
        navigate('/users');
      }
    }
    // store.removeUser();
  }, [store]);

  // const handleNavigateToChooseCreateType = () => {
  //   navigate('/cadastro');
  // };

  const inputEmail = useRef();
  const inputPassword = useRef(null);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleForgetPassword = () => {
  //   navigate('/esqueceu-senha');
  // };

  const closeModal = () => {
    setModalOpen(false);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleLogin = async (emailUsuarioParam = undefined, senhaUsuarioParam = undefined) => {
    const emailSend = emailUsuarioParam ?? email;
    const passwordSend = senhaUsuarioParam ?? password;
    if(!emailSend || !passwordSend){
      toast.error('Preencha email e senha para logar');
      return;
    }
    if(!validateEmail(emailSend)){
      toast.error('Preencha um email válido');
      return 
    }
    setLoading(true);
    await axiosInstance
      .post('/api/login', {
        email: emailSend,
        password: passwordSend,
      })
      .then((res) => {
        const loggedUser = res.data[0];

        store.setRole(loggedUser.role);
        store.setUser(loggedUser._id);
        store.setPlan(loggedUser?.plano ?? '');
        setLoading(false);

        if (loggedUser && loggedUser.role === 'aluno') {
          if (screen.width < 640 || screen.height < 480) {
            // versao celular
            navigate('/relacao-treinos');
          } else if (screen.width < 1024 || screen.height < 768) {
            // versão tablet
            navigate('/relacao-treinos');
          } else {
            // versão normal
            setModalOpen(true);
          }
        } else {
          navigate('/users');
        }
        logEvent(analytics, 'login', { user:  loggedUser.email })
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  };

  useEffect(() => {
    if(state){
      const { emailUsuario, senhaUsuario } = state;
      if(emailUsuario && senhaUsuario) handleLogin(emailUsuario, senhaUsuario)
    }
  }, [state])

  return (
    <>
      <Grid container style={{ justifyContent: 'center', marginTop: 50 }}>
        <Card style={{ maxWidth: 600, padding: 20, paddingTop: 40, backgroundColor: '#0E100F' }}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid>
              <img src={Logo} alt="logo" width={250} />
            </Grid>
            <Grid style={{ marginTop: 50 }} container justifyContent="center">
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                placeholder="Email"
                color="success"
                required
                autoComplete='new-password' 
                ref={inputEmail}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    document.getElementById('password').focus();
                  }
                }}
                value={email}
                onChange={handleChangeEmail}
                InputLabelProps={{ style: { color: '#fff' } }}
                sx={{ backgroundColor: '#1C1F1E', input: { color: 'white' } }}
              />
            </Grid>
            <Grid style={{ marginTop: 30 }} container justifyContent="center">
              <TextField
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                placeholder="Senha"
                color="success"
                id="password"
                value={password}
                ref={inputPassword}
                onChange={handleChangePassword}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin();
                  }
                }}
                InputLabelProps={{ style: { color: '#fff' } }}
                sx={{ backgroundColor: '#1C1F1E', input: { color: 'white' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff sx={{ fontSize: '2rem', color: '#727272' }} /> : <Visibility sx={{ fontSize: '2rem', color: '#727272' }} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid style={{ marginTop: 12 }} container justifyContent="left">
              <div
                style={{ fontSize: 16, color: '#FFFFFF', cursor: 'pointer' }}
                onClick={handleForgetPassword}
              >
                Esqueceu a senha?
              </div>
            </Grid> */}
            <Grid style={{ marginTop: 30 }} container justifyContent="center">
              <Button
                disabled={loading}
                onClick={() => handleLogin(undefined, undefined)}
                sx={{
                  background: '#ff66c4',
                  fontSize: 14,
                  transition: '0.6s',
                  fontWeight: 'bold',
                  ':hover': { background: '#14540d' },
                }}
                fullWidth
                variant="contained"
              >
                {loading ? <CircularProgress size={26} sx={{ color: '#ff66c4' }} /> : 'Entrar'}
              </Button>
            </Grid>
            {/* <Grid style={{ marginTop: 30 }} container justifyContent="center">
              <Button
                onClick={handleNavigateToChooseCreateType}
                sx={{
                  background: '#003F06',
                  fontSize: 14,
                  transition: '0.6s',
                  fontWeight: 'bold',
                  ':hover': {
                    opacity: 0.9,
                    background: '#0f6a08',
                  },
                }}
                fullWidth
                variant="contained"
              >
                Criar Cadastro
              </Button>
            </Grid> */}
          </Grid>
        </Card>
      </Grid>

      <Modal
        fullWidth
        open={modalOpen}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          border='none'
          sx={{
            p: 4,
            backgroundColor: '#1C1F1E',
            display: 'flex',
            flexDirection: 'column',
            width: 500
          }}
        >
          <Grid container sx={{ justifyContent: 'end' }}>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={closeModal} />
          </Grid>
          <Typography variant="h5" sx={{ mb: 4, color: '#FFFF00', textAlign: 'center' }}>
            Atenção!
          </Typography>
          <Typography variant="p" sx={{ color: '#bababa', mb: 4, textAlign: 'center' }}>
            O acesso de aluno deve ser realizado pelo nosso App.
          </Typography>
          <Grid container spacing={3} sx={{justifyContent: 'center'}}>
          <Grid item>
            <a rel="noreferrer" href="https://apps.apple.com/us/app/myappfitness/id6448712076" target="_blank"><img src={Apple} alt='apple' width={150} style={{ cursor: 'pointer' }} /></a>
          </Grid>
          <Grid item>
          <a rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.myappfitness' target="_blank"><img src={Google} alt='google' width={150} style={{ cursor: 'pointer' }} /></a>
          </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Login;
